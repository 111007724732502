// Dependencies
import Vue from "vue";
import persist from "./persist";

// Local variables and methods
const initialState = () => {
  return {
    isValidSession: false,
    user: null,
    googleEmail: null
  };
};

// Store
export default options => ({
  namespaced: true,
  state: () => initialState(),
  getters: {
    isAuth: state => state.isValidSession
  },
  actions: {
    async registerWithPassword({ commit }, { email, password }) {
      console.log(commit);
      try {
        const response = await Vue.$http.auth.register(email, password);
        console.log("Registration successful:", response.data);
        return response.data;
      } catch (error) {
        console.error("Registration failed:", error);
        throw new Error("Registration failed. Please try again.");
      }
    },
    register({ commit }, { name, email, password }) {
      console.log(commit);
      return new Promise((resolve, reject) => {
        Vue.$http.auth
          .register(name, email, password)
          .then(message => {
            console.log("Registration successful:", message);

            // Properly resolve the promise so the frontend knows registration is complete
            resolve(message);
          })
          .catch(error => {
            console.error("Registration failed:", error);
            reject(error);
          });
      });
    },
    login({ commit }, { email, password }) {
      return new Promise((resolve, reject) => {
        Vue.$http.auth
          .login(email, password)
          .then(message => {
            console.log(message);
            Vue.$http.auth
              .getUser()
              .then(user => {
                commit("update", { key: "user", value: user });
                persist.setUser(user);

                commit("update", { key: "isValidSession", value: true });

                if (options.onLoginSuccess) {
                  options.onLoginSuccess(user);
                }

                resolve({
                  user
                });
              })
              .catch(error => {
                reject(error);
              });
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    resendActivation({ commit }, { email }) {
      return new Promise((resolve, reject) => {
        Vue.$http.auth
          .resendActivation(email)
          .then(response => {
            commit("update", {
              key: "activationMessage",
              value: "Activation email sent!"
            });
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    logout() {
      return new Promise((resolve, reject) => {
        Vue.$http.auth
          .logout()
          .then(message => {
            console.log(message);
            persist.reset();
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    tempSession({ commit }, data) {
      return new Promise((resolve, reject) => {
        Vue.$http.auth
          .tempSession(data)
          .then(response => {
            console.log(response);
            Vue.$http.auth
              .getUser()
              .then(user => {
                commit("update", { key: "user", value: user });
                persist.setUser(user);

                commit("update", { key: "isValidSession", value: true });

                if (options.onLoginSuccess) {
                  options.onLoginSuccess(user);
                }

                resolve({
                  user
                });
              })
              .catch(error => {
                reject(error);
              });
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  },
  mutations: {
    setGoogleEmail(state, email) {
      state.googleEmail = email;
    },
    updateUser(state, payload) {
      if (state.user) {
        state.user = { ...state.user, ...payload };
        persist.setUser(state.user);
      }
    },
    reset(state) {
      Object.keys(initialState()).forEach(key => (state[key] = null));
    },
    update(state, { key, value }) {
      if (!Object.prototype.hasOwnProperty.call(initialState(), key)) {
        console.warn(`Can not update key "${key}" of the auth store.`);
        return;
      }

      state[key] = value;
    }
  }
});
