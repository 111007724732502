<template>
  <div class="container" @close="close()">
    <h2 class="title">Purchase a Subscription</h2>
    <p class="subtitle">Choose the plan that works for you</p>

    <!-- Billing Toggle -->
    <div class="billing-toggle">
      <button
        class="billing-option"
        :class="{ active: isYearly }"
        @click="isYearly = true"
      >
        Yearly Billing
      </button>
      <button
        class="billing-option"
        :class="{ active: !isYearly }"
        @click="isYearly = false"
      >
        Monthly Billing
      </button>
    </div>

    <!-- Subscription Plans -->
    <div class="plans">
      <div class="plan" v-for="plan in plans" :key="plan.name">
        <div class="card">
          <h5 class="plan-title" :class="plan.colorClass">{{ plan.name }}</h5>
          <h3 class="plan-price">
            €{{ isYearly ? plan.priceYearly : plan.priceMonthly
            }}<small>/month</small>
          </h3>
          <p class="billing-info">
            Billed {{ isYearly ? "yearly" : "monthly" }}
          </p>
          <button
            v-if="isActivePlan(plan)"
            class="subscribe-btn btn-secondary"
            disabled
          >
            Active
          </button>
          <button
            v-else
            class="subscribe-btn"
            :class="plan.buttonClass"
            @click="handlePlanSelection(plan, isYearly)"
          >
            Subscribe
          </button>
          <ul class="feature-list">
            <li v-for="feature in plan.features" :key="feature">
              ✓ <span class="feature-text">{{ feature }}</span>
            </li>
          </ul>
          <p class="switch-billing">
            <a href="#" @click.prevent="isYearly = !isYearly">
              Switch to {{ isYearly ? "monthly" : "yearly" }} billing
              {{ isYearly ? "" : "(20% Off)" }} →
            </a>
          </p>
        </div>
      </div>
    </div>
    <SubscriptionChangeConfirmDialog
      v-if="showDialog"
      :showDialog="showDialog"
      :selectedPlan="selectedPlan"
      :renewalDate="nextRenewalDate"
      :changeType="changeType"
      :selectedPlanIsYearly="isYearly"
      @confirm-change="confirmPlanChange"
      @close="showDialog = false"
    />
  </div>
</template>

<script>
import Vue from "vue";
import SubscriptionChangeConfirmDialog from "./SubscriptionChangeConfirmDialog.vue";

export default {
  components: { SubscriptionChangeConfirmDialog },
  props: { currentPriceId: { default: null, type: [String, Object] } },

  mounted() {
    console.log(
      "[MOUNTED] Initializing component with price ID:",
      this.currentPriceId
    );

    this.$nextTick(() => {
      this.setCurrentPlan();
    });
  },
  data() {
    return {
      isYearly: true,
      currentPlanisYearly: true,
      user: this.$store.state.auth.user,
      showUpgradeDialog: false,
      selectedPlan: null,
      changeType: "",
      showDialog: false,
      nextRenewalDate: "Feb 15, 2025",

      plans: [
        {
          name: "Basic Plan",
          priceMonthly: 10,
          priceYearly: 8,
          stripePriceIdMonthly:
            window.location.hostname !== "localhost"
              ? "price_1QrNWjP21DGvyqlHy6r7gNxy"
              : "price_1QrRvOP21DGvyqlHdKB8LBYh",
          stripePriceIdYearly:
            window.location.hostname !== "localhost"
              ? "price_1QtYdLP21DGvyqlH94MBbfvj"
              : "price_1Qri3QP21DGvyqlH3RbAfH3I",
          colorClass: "text-success",
          buttonClass: "btn-success",
          features: [
            "Standard email support",
            "2GB secure cloud storage",
            "5 monthly share links",
            "5 monthly drop links",
            "Automated data anonymization",
            "Comprehensive patient & study dashboards",
            "Measurement tools & annotations",
            "Multi-Planar Reconstruction (MPR) visualization",
            "Interactive volume rendering"
          ]
        },
        {
          name: "Pro Plan",
          priceMonthly: 60,
          priceYearly: 48,
          stripePriceIdMonthly:
            window.location.hostname !== "localhost"
              ? "price_1QtX6TP21DGvyqlHPcEEzTFa"
              : "price_1QtX8rP21DGvyqlHvsznqBLh",
          stripePriceIdYearly:
            window.location.hostname !== "localhost"
              ? "price_1QtX70P21DGvyqlHxOwnRMkl"
              : "price_1QtX9SP21DGvyqlHSZu29pE8",
          colorClass: "text-warning",
          buttonClass: "btn-warning",
          features: [
            "All Basic Plan features",
            "Priority support",
            "50GB storage",
            "100 monthly share links",
            "100 monthly drop links",
            "Remote PACS integration",
            "Segmentation masks support",
            "3D surfaces support",
            "Export masks and surfaces for training datasets",
            "Integrate your AI server (get in touch with our sales team at sales@dicom.vision first)"
          ]
        }
      ]
    };
  },
  methods: {
    setCurrentPlan() {
      console.log("[SET CURRENT PLAN] Checking current plan...");

      const foundPlan = this.plans.find(
        plan =>
          plan.stripePriceIdMonthly === this.currentPriceId ||
          plan.stripePriceIdYearly === this.currentPriceId
      );

      if (foundPlan) {
        this.currentPlan = foundPlan;
        this.currentPlanisYearly =
          this.currentPlan.stripePriceIdYearly === this.currentPriceId;

        console.log(
          `[CURRENT PLAN SET] Plan: ${this.currentPlan.name}, Yearly: ${this.isYearly}`
        );

        // 🚀 Force Vue to update UI immediately
        this.$nextTick(() => {
          this.$forceUpdate();
          console.log("[FORCED UPDATE] Vue UI reactivity triggered.");
        });
      } else {
        console.warn(
          "[WARNING] No active plan found for given price ID:",
          this.currentPriceId
        );
      }
    },
    isActivePlan(plan) {
      if (!this.currentPlan) {
        console.warn(
          "[WARNING] Skipping active plan check: Current plan is not set yet."
        );
        return false;
      }

      const isSamePlan = this.currentPlan.name === plan.name;
      const isSameBillingCycle = this.isYearly
        ? this.currentPlan.stripePriceIdYearly === this.currentPriceId
        : this.currentPlan.stripePriceIdMonthly === this.currentPriceId;

      const isActive = isSamePlan && isSameBillingCycle;

      console.log(
        `[CHECK ACTIVE PLAN] Plan: ${plan.name}, Active: ${isActive}, Billing Cycle Match: ${isSameBillingCycle}`
      );

      return isActive;
    },
    close() {
      console.log("closed");
      this.$emit("close"); // todo maybe is not correct
    },
    async subscribe(plan) {
      const priceId = this.isYearly
        ? plan.stripePriceIdYearly
        : plan.stripePriceIdMonthly;

      try {
        const response = await Vue.http.post(
          "api/create-checkout-session/",
          {
            price_id: priceId
          },
          {
            headers: {
              "Content-Type": "application/json"
            }
          }
        );

        const data = response.data;
        if (data.url) {
          window.location.href = data.url;
        } else {
          console.error("Error:", data.error);
        }
      } catch (error) {
        console.error("Checkout error:", error);
      }
    },
    confirmUpgrade(option) {
      this.showUpgradeDialog = false;
      const priceId = this.isYearly
        ? this.selectedPlan.stripePriceIdYearly
        : this.selectedPlan.stripePriceIdMonthly;

      // Handle upgrade logic
      if (option === "immediately") {
        this.processUpgrade(priceId);
      } else {
        alert("Your upgrade will take effect at your next renewal date.");
      }
    },
    processUpgrade(priceId) {
      alert("Upgrading immediately. Redirecting to checkout...");
      console.log(priceId);
      // Call API to process the upgrade
    },
    getPlanPrice(plan) {
      return this.isYearly ? plan.priceYearly : plan.priceMonthly;
    },
    handlePlanSelection(plan) {
      if (!this.currentPlan) {
        return this.subscribe(plan);
      }
      console.log(
        `[PLAN SELECTION] Selected Plan: ${JSON.stringify(
          plan,
          null,
          2
        )}, yearly: ${this.isYearly}`
      );

      if (this.isActivePlan(plan)) {
        console.log(
          "[PLAN SELECTION] Plan is already active. No action needed."
        );
        return;
      }

      const isUpgrade = plan.priceMonthly > this.getCurrentPlanPrice();
      const isBillingCycleChange =
        plan.name === this.currentPlan.name &&
        this.currentPlanisYearly !== this.isYearly;

      // Detect restricted change: Yearly Basic → Monthly Pro
      if (
        this.currentPlanisYearly &&
        this.currentPlan.name === "Basic Plan" &&
        plan.name === "Pro Plan" &&
        !this.isYearly
      ) {
        console.log(
          "[RESTRICTED CHANGE] Yearly Basic → Monthly Pro is not allowed."
        );
        alert(
          "If you're switching from a yearly plan to an upgraded monthly plan, the website won’t allow an immediate change. Please contact our billing support team for assistance."
        );
        return;
      }

      console.log(
        `[CHANGE DETECTED] Type: ${
          isBillingCycleChange
            ? "Billing Cycle"
            : isUpgrade
            ? "Upgrade"
            : "Downgrade"
        }`
      );

      this.selectedPlan = plan;
      this.changeType = isBillingCycleChange
        ? "billingCycle"
        : isUpgrade
        ? "upgrade"
        : "downgrade";
      this.showDialog = true;
    },
    async confirmPlanChange(option) {
      console.log(
        `[PLAN CHANGE CONFIRMED] Option: ${option}, Plan: ${this.selectedPlan.name}, Type: ${this.changeType}`
      );

      this.showDialog = false;

      // Determine the new price ID
      const newPriceId = this.isYearly
        ? this.selectedPlan.stripePriceIdYearly
        : this.selectedPlan.stripePriceIdMonthly;

      try {
        const response = await Vue.http.post("api/update-subscription/", {
          new_price_id: newPriceId,
          update_type: option // "immediate" or "renewal"
        });

        alert(response.data.message);

        // Refresh subscription details after update
        this.$emit("close"); // Close modal and refresh parent component
      } catch (error) {
        console.error("Subscription update failed:", error);
        alert("Failed to update subscription. Please try again.");
      }
    },
    getCurrentPlanPrice() {
      const price = this.isYearly
        ? this.currentPlan?.priceYearly
        : this.currentPlan?.priceMonthly;
      console.log(`[GET CURRENT PLAN PRICE] Price: ${price}`);
      return price;
    }
  }
};
</script>

<style scoped>
.container {
  max-width: 1100px;
  margin: auto;
  text-align: center;
  margin-top: 5rem;
}
.title {
  font-weight: bold;
}
.subtitle {
  color: #6c757d;
}
.billing-toggle {
  background: #222;
  padding: 5px;
  border-radius: 10px;
  display: inline-flex;
  margin-bottom: 1.5rem;
}
.billing-option {
  background: transparent;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  color: #bbb;
  cursor: pointer;
  transition: all 0.3s ease;
}
.billing-option.active {
  background: #444;
  color: #fff;
  border-radius: 5px;
}
.plans {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}
.plan {
  flex: 1 1 300px;
  max-width: 350px;
}
.card {
  background: #fff;
  border-radius: 10px;
  padding: 1.5rem;
  text-align: center;
  width: 100%;
}
.plan-title {
  font-weight: bold;
}
.plan-price {
  font-weight: bold;
  font-size: 1.75rem;
}
.billing-info {
  color: #6c757d;
}
.subscribe-btn {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  /* border: none; */
  /* cursor: pointer; */
  border-radius: 5px;
}
button.subscribe-btn {
  border-style: solid;
  border-width: 1px;
  border-color: initial;
}
.feature-list {
  list-style-type: none;
  padding: 0;
  margin: 1rem 0 0;
  font-size: 14px;
  color: #444;
  text-align: left;
  padding-left: 20px;
}
.feature-list li {
  margin: 5px 0;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.feature-text {
  margin-left: 5px;
}
.switch-billing {
  margin-top: 1rem;
  font-size: 14px;
  color: #777;
  white-space: nowrap;
}
.switch-billing a {
  text-decoration: none;
  color: inherit;
}
.switch-billing a:hover {
  text-decoration: underline;
}
@media (min-width: 768px) {
  .plans {
    flex-wrap: nowrap;
    justify-content: center;
  }
}
</style>
