<template>
  <div v-if="showDialog" class="modal">
    <div class="modal-content">
      <h3>{{ getTitle }}</h3>

      <p v-if="isDowngrade">
        If you switch to a lower-tier plan, the change will take effect at your next renewal, not immediately.
        <strong>No refunds</strong> will be provided.
      </p>

      <p v-else-if="isBillingCycleChange">
        Switching from <strong>{{ isYearlyToMonthly ? "Yearly to Monthly" : "Monthly to Yearly" }}</strong> will:
        <ul>
          <li v-if="isYearlyToMonthly">Take effect at your next renewal.</li>
          <li v-else>Take effect immediately, and your unused balance will be credited.</li>
        </ul>
      </p>

      <p v-else>
        Upgrading will give you immediate access to the new plan.
        <strong>Do you want to apply the change immediately or wait until renewal?</strong>
      </p>

      <div v-if="isUpgrade && !isBillingCycleChange" class="options">
        <label>
          <input type="radio" v-model="upgradeOption" value="later" />
          Upgrade at renewal ({{ renewalDate }})
        </label>
        <label>
          <input type="radio" v-model="upgradeOption" value="immediately" />
          Upgrade immediately
        </label>
      </div>

      <div class="buttons">
        <button @click="closeDialog" class="btn-secondary">Cancel</button>
        <button @click="confirmChange" class="btn-primary">
          {{ isDowngrade || isYearlyToMonthly ? "Change Plan" : "Confirm" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ["showDialog", "selectedPlan", "selectedPlanIsYearly", "renewalDate", "changeType"],
  data() {
    return {
      upgradeOption: "later",
    };
  },
  computed: {
    isDowngrade() {
      return this.changeType === "downgrade";
    },
    isUpgrade() {
      return this.changeType === "upgrade";
    },
    isBillingCycleChange() {
      return this.changeType === "billingCycle";
    },
    isYearlyToMonthly() {
      console.log("this.selectedPlanIsYearly", this.selectedPlanIsYearly)
      return this.isBillingCycleChange && !this.selectedPlanIsYearly;
    },
    getTitle() {
      if (this.isDowngrade) return "Confirm Downgrade";
      if (this.isBillingCycleChange) return "Switch Billing Cycle";
      return "Upgrade Plan";
    }
  },
  methods: {
    confirmChange() {
      this.$emit("confirm-change", this.isDowngrade || this.isYearlyToMonthly ? "deferred" : this.upgradeOption);
    },
    closeDialog() {
      this.$emit("close");
    },
  },
};
</script>

<style>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
}
.buttons {
  display: flex;
  justify-content: space-between;
}
</style>
