<template>
  <div class="text-center">
    <v-dialog
      overlay-color="black"
      overlay-opacity="0.7"
      v-model="dialog"
      width="700"
      :dark="darkMode"
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="text-center lh-small" v-bind="attrs" v-on="on">
          <v-icon :color="iconColor">
            {{ item.icon }}
          </v-icon>
          <div>
            <b :class="`${iconColor}--text`">{{ item.name }}</b>
          </div>
        </div>
      </template>

      <v-card :dark="darkMode">
        <v-card-title class="headline align-center">
          Software Informations
        </v-card-title>

        <v-card-text style="user-select: text">
          <template>
            <v-container :dark="darkMode">
              <v-row no-gutters>
                <v-col cols="12" sm="12">
                  <v-card class="pa-3 headline" outlined tile>
                    <div class="d-flex align-center justify-center">
                      <v-img
                        class="mr-2"
                        contain
                        :max-height="$vuetify.application.top - 15"
                        max-width="40"
                        :src="logo"
                      />
                      <span
                        ><b>DICOM </b>VISION<sup>®</sup>
                        {{ `v${version}` }}</span
                      >
                    </div>
                  </v-card>
                </v-col>
              </v-row>
              <!-- <v-row no-gutters>
                <v-col cols="12" sm="6">
                  <v-card class="pa-3 text-center" outlined tile>
                    <v-img
                      class="mx-auto mb-1"
                      contain
                      :max-height="$vuetify.application.top - 15"
                      max-width="60%"
                      :src="medDevicePath"
                      :style="imgStyle"
                    />
                    Software classified as a Medical Device
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-card
                    class="pa-3 fill-height d-flex flex-column justify-center text-center"
                    outlined
                    tile
                  >
                    <v-img
                      class="mx-auto"
                      contain
                      :max-height="$vuetify.application.top - 15"
                      max-width="24%"
                      :src="ceMarkPath"
                      :style="imgStyle"
                    />
                    <span
                      style="font-size: 16px; letter-spacing: 2px; margin-left: 4px;"
                      >0051</span
                    >
                  </v-card>
                </v-col>
              </v-row> -->
              <v-row no-gutters>
                <v-col cols="12" sm="3">
                  <v-card
                    class="pa-3 d-flex flex-column justify-center"
                    outlined
                    tile
                  >
                    <v-img
                      class="mx-auto mb-1 mt-1"
                      contain
                      :max-height="$vuetify.application.top - 15"
                      max-width="30%"
                      :src="manufPath"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" sm="9">
                  <v-card
                    class="pa-3 fill-height d-flex flex-column justify-center"
                    outlined
                    tile
                  >
                    <div style="margin-bottom: 7px;">
                      DICOM Vision s.r.l. Piazzale della Repubblica, 2 - 24122
                      Bergamo
                    </div>

                    <div style="display: flex; align-items: center;">
                      <a
                        href="https://www.dicom.vision"
                        target="_blank"
                        style="text-decoration: none;"
                      >
                        https://www.dicom.vision
                      </a>
                      <span style="margin-left: 10px; margin-right: 10px;">
                        -
                      </span>
                      <a
                        href="mailto:info@dicom.vision"
                        target="_blank"
                        style="text-decoration: none;"
                      >
                        info@dicom.vision
                      </a>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" sm="3">
                  <v-card class="pa-3" outlined tile>
                    <v-img
                      class="mx-auto"
                      contain
                      :max-height="$vuetify.application.top - 15"
                      max-width="30%"
                      :src="manufDatePath"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" sm="9">
                  <v-card
                    class="pa-3 fill-height d-flex flex-column justify-center"
                    outlined
                    tile
                  >
                    {{ buildDate }}
                  </v-card>
                </v-col>
              </v-row>
              <!-- <v-row no-gutters>
                <v-col cols="12" sm="3">
                  <v-card class="pa-3 text-center" outlined tile>
                    License Number
                  </v-card>
                </v-col>
                <v-col cols="12" sm="9">
                  <v-card class="pa-3 fill-height" outlined tile>
                    {{ licenseNumber }}
                  </v-card>
                </v-col>
              </v-row> -->
              <!-- <v-row no-gutters>
                <v-col cols="12" sm="3">
                  <v-card class="pa-3 text-center" outlined tile>
                    License Expiration
                  </v-card>
                </v-col>
                <v-col cols="12" sm="9">
                  <v-card
                    class="pa-3 fill-height d-flex flex-column justify-center"
                    outlined
                    tile
                  >
                    {{ licenseExpiration }}
                  </v-card>
                </v-col>
              </v-row> -->
              <!-- <v-row no-gutters>
                <v-col cols="12" sm="3">
                  <v-card class="pa-3 text-center" outlined tile>
                    Software Version
                  </v-card>
                </v-col>
                <v-col cols="12" sm="9">
                  <v-card class="pa-3" outlined tile
                    >{{ `v${version}` }}
                  </v-card>
                </v-col>
              </v-row> -->
              <v-row no-gutters>
                <v-col cols="12" sm="3">
                  <v-card class="pa-3 text-center" outlined tile>
                    <v-img
                      class="mx-auto"
                      contain
                      :max-height="$vuetify.application.top - 15"
                      max-width="30%"
                      :src="snPath"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" sm="9">
                  <v-card class="pa-3 fill-height" outlined tile>
                    <template v-if="buildId">
                      {{ buildId }}

                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            text
                            @click="copyToClipBoard"
                            color="primary"
                            v-bind="attrs"
                            v-on="on"
                            x-small
                            height="100%"
                          >
                            <v-icon small>mdi-content-copy</v-icon>
                          </v-btn>
                        </template>
                        <span>Copy Build ID to clipboard</span>
                      </v-tooltip>
                    </template>
                    <template v-else>
                      <span style="color: red;"
                        >Error: Build ID is missing.</span
                      >
                    </template>
                  </v-card>
                </v-col>
              </v-row>
              <!-- <v-row no-gutters>
                <v-col cols="12" sm="3">
                  <v-card class="pa-3" outlined tile>
                    <v-img
                      class="mx-auto"
                      contain
                      :max-height="$vuetify.application.top - 15"
                      max-width="30%"
                      :src="udiPath"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" sm="9">
                  <v-card
                    class="pa-3 fill-height d-flex flex-column justify-center"
                    outlined
                    tile
                  >
                  </v-card>
                </v-col>
              </v-row> -->
              <!-- <v-row no-gutters>
                <v-col cols="12" sm="3">
                  <v-card
                    class="pa-3 d-flex flex-column justify-center"
                    outlined
                    tile
                  >
                    <v-img
                      class="mx-auto"
                      contain
                      :max-height="$vuetify.application.top - 15"
                      max-width="30%"
                      :src="instructPath"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" sm="9">
                  <v-card
                    class="pa-3 fill-height d-flex flex-column justify-center"
                    outlined
                    tile
                  >
                    <div>
                      Please read the instructions carefully:
                      <v-btn
                        v-if="user.is_admin"
                        :href="adminManualURL"
                        download
                        color="primary"
                        target="_blank"
                        :dark="darkMode"
                        text
                        small
                        style="margin-bottom: 3px;"
                      >
                        ifu.dicom.vision
                      </v-btn>
                    </div>
                  </v-card>
                </v-col>
              </v-row> -->
            </v-container>
          </template>
        </v-card-text>

        <v-divider></v-divider>
        <div class="mt-2 mb-2 text-center">
          {{ $t("auth.cookiePolicy") }}
          <a
            href="https://dicom.vision/web-privacy-policy-cookie"
            target="_blank"
            >Cookie Policy</a
          >
        </div>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import buildData from "@/../BUILD_ID.json";
import { version } from "@/../package.json";

export default {
  name: "BuildInfo",
  props: {
    active: { default: false, type: Boolean },
    item: { required: true, type: Object }
  },
  data() {
    return {
      dialog: false,
      version,
      user: this.$store.state.auth.user,
      buildId: buildData ? buildData.build_id : null,
      buildDate: buildData ? buildData.build_date : null,
      licenseNumber: process.env.APP_CUSTOMER.licenseNumber,
      licenseExpiration: process.env.APP_CUSTOMER.licenseExpiration,
      ceMarkPath: process.env.APP_CUSTOMER.ceMark,
      certCode: process.env.APP_CUSTOMER.certCode,
      manufPath: process.env.APP_CUSTOMER.manufLogo,
      snPath: process.env.APP_CUSTOMER.serialNumberLogo,
      udiPath: process.env.APP_CUSTOMER.udiLogo,
      manufDatePath: process.env.APP_CUSTOMER.manufDateLogo,
      instructPath: process.env.APP_CUSTOMER.instructLogo,
      medDevicePath: process.env.APP_CUSTOMER.medDeviceLogo,
      adminManualURL: process.env.APP_CUSTOMER.adminManualURL,
      logo: process.env.APP_CUSTOMER.upperLeftLogo
    };
  },
  computed: {
    ...mapGetters(["darkMode"]),
    iconColor() {
      return this.active ? "primary" : "white";
    },
    imgStyle() {
      return {
        filter: this.darkMode ? "invert(90%)" : ""
      };
    }
  },
  methods: {
    async copyToClipBoard() {
      await navigator.clipboard.writeText(this.buildId);
      this.$store.commit("raiseSnackbar", {
        text: "Build Id copied to clipboard",
        color: "primary",
        timeout: 2000
      });
    }
  }
};
</script>
