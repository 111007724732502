// Dependencies
import persist from "./persist";
import LoginForm from "./components/LoginForm";
import AuthWrapper from "./components/Wrapper";
import Vue from "vue";

// Local variables
//let alreadyAutoLoggedIn = false;

// Auth plugin routes
export const getRoutes = options => {
  const Wrapper = options.wrapperComponent
    ? options.wrapperComponent
    : AuthWrapper;

  return [
    {
      component: Wrapper,
      path: `${options.baseRoute}/login`,
      meta: {
        autoLogin: options.autoLogin,
        guest: true
      },
      children: [
        {
          path: "",
          name: "login",
          component: LoginForm,
          meta: { guest: true },
          props: route => ({
            ...route.query,
            allowPasswordReset: options.allowPasswordReset,
            allowUserRegistration: options.allowUserRegistration,
            title: "",
            dark: true,
            baseRoute: options.baseRoute
          })
        }
      ]
    },
    options.allowPasswordReset
      ? {
          component: Wrapper,
          path: `${options.baseRoute}/forgot-password`,
          meta: { guest: true },
          props: () => ({
            dark: true
          }),
          children: [
            {
              path: "",
              name: "forgot-password",
              meta: { guest: true },
              props: () => ({
                dark: true
              }),
              component: () => import("./components/ForgotPassword")
            }
          ]
        }
      : null,
    options.allowPasswordReset
      ? {
          component: Wrapper,
          path: `${options.baseRoute}/reset-password/:uid/:token`,
          meta: { guest: true },
          children: [
            {
              path: "",
              name: "reset-password",
              meta: { guest: true },
              component: () => import("./components/ChangePassword"),
              props: true
            }
          ]
        }
      : null,
    options.allowUserRegistration
      ? {
          component: Wrapper,
          path: `${options.baseRoute}/register`,
          meta: { guest: true },
          props: route => ({
            ...route.query,
            title: "",
            dark: route.query.dark === "true",
            baseRoute: options.baseRoute
          }),
          children: [
            {
              path: "",
              name: "register",
              meta: { guest: true },
              component: () => import("./components/RegisterForm")
            }
          ]
        }
      : null
  ].filter(Boolean); // equal to _.compact
};

// Before each navigation guard
export const getBeforeEachGuard = options => {
  return async function(to, from, next) {
    let isValidSession = options.store.getters["auth/isAuth"];
    let fetchedUser = null;

    try {
      const response = await Vue.$http.get("auth/check-session");
      if (response.status === "logged") {
        isValidSession = true;
        fetchedUser = response.user; // Get user from API
      } else {
        isValidSession = false;
      }
    } catch (error) {
      console.log(error);
      isValidSession = false;
    }

    options.store.commit("auth/update", {
      key: "isValidSession",
      value: isValidSession
    });

    // Only update user if there is a valid session and user data is different
    if (isValidSession) {
      const currentUser = persist.getUser();

      // Update user in Vuex only if it's not already stored or changed
      if (
        !options.store.state.auth.user ||
        JSON.stringify(currentUser) !== JSON.stringify(fetchedUser)
      ) {
        options.store.commit("auth/update", {
          key: "user",
          value: fetchedUser
        });
        persist.setUser(fetchedUser); // Persist user only when needed
        console.log("User updated:", fetchedUser);
      }

      // Update Vue instance properties
      Vue.prototype.$isDemoUser = fetchedUser?.is_demo;
      Vue.prototype.$isAdminUser = fetchedUser?.is_admin;
      Vue.prototype.$isTempUser = fetchedUser?.is_temp;
    }

    // Handle navigation rules
    if (to.matched.some(record => record.meta.guest)) {
      if (!isValidSession) {
        next();
      } else {
        next(`${options.baseRoute}${options.redirectAuthUsers}`);
      }
    } else if (to.matched.some(record => record.meta.auth)) {
      if (!isValidSession) {
        next(`${options.baseRoute}${options.redirectGuestUsers}`);
      } else {
        if (options.store.state.auth.user.is_temp) {
          next(false);
        } else {
          next();
        }
      }
    } else {
      next();
    }
  };
};
