<template>
  <div>
    <!-- General Form Fields (Excluding Checkboxes) -->
    <component
      v-if="getComponentName(field) !== 'v-checkbox'"
      :is="getComponentName(field)"
      :append-icon="field.appendIcon"
      :autofocus="field.autofocus"
      :clearable="field.clearable"
      :disabled="loading || getDisabled(field, value)"
      :hint="field.hint"
      :items="field.items"
      :label="field.label"
      :menuProps="
        getComponentName(field) === 'v-select' ? { dark: dark } : null
      "
      :placeholder="field.placeholder"
      :required="required"
      :rules="dirty ? getRules(field) : undefined"
      :type="field.type"
      :dark="dark"
      v-model="value[field.key]"
      @icon-click="() => $emit('icon-click', field)"
    >
      <template slot="append">
        <v-icon v-if="field.appendIcon" small>{{ field.appendIcon }}</v-icon>
      </template>
    </component>

    <!-- Special Handling for Checkboxes -->
    <v-checkbox
      v-else
      v-model="value[field.key]"
      :disabled="loading"
      :rules="dirty ? getRules(field) : undefined"
      class="checkbox-field"
      :dark="dark"
    >
      <template v-slot:label>
        <span v-html="field.label" @click.stop="stopCheckboxToggle"></span>
      </template>
    </v-checkbox>

    <!-- Additional Slot Component (if provided) -->
    <component
      v-if="field.slot"
      :dark="dark"
      :is="field.slot"
      v-bind="{ field, value }"
    />
  </div>
</template>

<script>
import { rules } from "./rules";
import { VTextField, VSelect, VCheckbox } from "vuetify/lib";
import PasswordField from "../auth/components/PasswordField.vue";

export default {
  name: "Field",
  components: { VTextField, VSelect, VCheckbox, PasswordField },
  props: {
    dark: { default: false, type: Boolean },
    dirty: { default: false, type: Boolean },
    field: { required: true, type: Object },
    loading: { default: false, type: Boolean },
    value: { default: () => ({}), type: Object }
  },
  computed: {
    required() {
      return this.field.required
        ? this.field.required(this.field, this.value)
        : false;
    }
  },
  methods: {
    getComponentName(field) {
      if (field.component) return field.component;
      return (
        {
          boolean: "v-checkbox", // Ensure "boolean" maps correctly
          select: "v-select",
          text: "v-text-field"
        }[field.type] || "v-text-field"
      );
    },
    getRules(field) {
      let result = [];
      if (field.required) result.push(...rules.required);
      if (rules[field.type]) result.push(...rules[field.type]);
      return [...result, ...(field.rules || [])];
    },
    getDisabled(field, value) {
      // Ensures disabled is always a boolean (fixes issue)
      return typeof field.disabled === "function"
        ? field.disabled(field, value)
        : !!field.disabled;
    },
    stopCheckboxToggle(event) {
      if (event.target.tagName === "A") {
        event.stopPropagation(); // Prevents checkbox from toggling when clicking links
      }
    }
  }
};
</script>

<style scoped>
/* Ensure Labels & Links Are Visible in Dark Mode */
::v-deep .v-label {
  text-transform: capitalize;
  color: white !important; /* Ensures visibility in dark themes */
}

/* Fix Checkbox Visibility */
::v-deep .v-input--selection-controls {
  color: white !important; /* Ensures checkboxes are visible */
}

/* Style Links Inside Checkboxes */
::v-deep .v-input--selection-controls__input a {
  color: #42a5f5; /* Light blue color */
  text-decoration: underline;
}

::v-deep .v-input--selection-controls__input a:hover {
  color: #64b5f6; /* Slightly brighter blue on hover */
}
</style>
