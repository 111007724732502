<template>
  <div class="h-100">
    <app-home />

    <v-dialog
      overlay-color="black"
      overlay-opacity="0.7"
      persistent
      max-width="350"
      v-model="dialog.show"
    >
      <v-card>
        <v-card-title class="headline">{{ dialog.title }}</v-card-title>
        <v-card-text>{{ dialog.text }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="D1"
            v-if="dialog.action"
            color="primary"
            text
            @click="dialog.action.onClick"
          >
            {{ dialog.action.title }}
          </v-btn>
          <v-btn id="D2" text @click="dialog.show = false"> Dismiss </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      overlay-color="black"
      overlay-opacity="0.7"
      persistent
      max-width="350"
      v-model="ToSDialog.show"
    >
      <v-card>
        <v-card-title class="headline">Terms of Service</v-card-title>
        <v-card-text>
          <p>
            Please read and accept our Terms of Service to continue using the
            application.
          </p>
          <div class="tos-content">
            <div class="tos-scrollable">
              {{ ToSContent }}
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="acceptTos">
            Accept
          </v-btn>
          <v-btn text @click="declineTos">
            Decline
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="subscriptionDialog.show" max-width="800">
      <v-card>
        <PurchaseSubscription />

        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn text color="primary" @click="subscriptionDialog.show = false"
            >Close</v-btn
          > -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { createRgbVarsForThemes } from "@/js/utils.vuetify.css";
import { getPredictors, getUserSettings } from "@/js/api.schema";
import * as wsSingle from "@/js/api.websocket.single";
import {
  disconnect as disconnectSriesDownload,
  openSeriesWebsocket as openDownloadWs
} from "@/js/api.series.download";
import {
  disconnect as disconnectSriesUpload,
  openSeriesWebsocket as openUploadWs
} from "@/js/api.series.upload";
import AppHome from "@/views/Home";
import { mapGetters } from "vuex";
import Vue from "vue";
import PurchaseSubscription from "@/components/PurchaseSubscription.vue";
import ToSString from "./ToS.js"; // if in same directory

export default {
  name: "App",
  components: { AppHome, PurchaseSubscription },
  data() {
    return {
      dialog: { show: false },
      ToSDialog: { show: false },
      subscriptionDialog: { show: false }
      // reconnectionCounter: 0
    };
  },
  created() {
    // Connect to websocket, disconnect on page unload

    try {
      wsSingle.disconnect();
      disconnectSriesDownload();
      disconnectSriesUpload();
    } catch (error) {
      // Handle the error here, you can log it or perform other actions.
      console.error("An error occurred:", error);
    }
  },
  mounted() {
    createRgbVarsForThemes(this.$vuetify.theme.themes.light);

    this.subscriptionCheckInterval = setInterval(() => {
      if (this.isAuth && !this.hasPaidSubscription) {
        this.subscriptionDialog.show = true;
      }
    }, 3000); // Check every 5 seconds
  },
  computed: {
    ...mapGetters("auth", ["isAuth"]),
    hasAcceptedTos() {
      // Check if the user has accepted the TOS from the user object
      return this.$store.state.auth.user?.accepted_tos || false;
    },
    ToSContent() {
      return ToSString; // Ensure Vue tracks it
    },
    hasPaidSubscription() {
      console.log(this.$store.state.auth.user);
      return this.$store.state.auth.user?.subscription_tier !== "free";
    }
  },
  methods: {
    isDownloadEnabled() {
      return true; //return !this.isAuth;
    },
    isUploadEnabled() {
      return true; // return this.isAuth && !this.$isDemoUser;
    },
    connectWebsocket() {
      const onClose = event => {
        this.dialog = {
          action: {
            onClick: () => {
              this.dialog.show = false;
              this.connectWebsocket();
            },
            title: "try-again"
          },
          show: true,
          text: `URL:${event.target.url} EVENT:${event.type}`,
          title: "Websocket connection error"
        };
      };
      const onError = event =>
        (this.dialog = {
          show: true,
          text: `URL:${event.target.url} EVENT:${event.type}`,
          title: "Websocket connection error"
        });

      const userId =
        (this.$store.state.auth.user && this.$store.state.auth.user.uuid) ||
        this.user;
      if (this.isDownloadEnabled())
        openDownloadWs(userId, onClose, onError, this.$router);
      if (this.isUploadEnabled()) openUploadWs(userId, onClose, onError);
    },
    async loadPredictors() {
      // get all predictors
      const predictors = await getPredictors();
      this.$store.commit("viewer/initPredictors", predictors);
    },
    loadUserSettings() {
      getUserSettings()
        .then(data => {
          // update store values
          this.$store.commit("viewer/initOptions", data);
        })
        .catch(error => console.error(error));
    },
    async acceptTos() {
      try {
        // Call the backend API to update the TOS acceptance status
        await Vue.$http.patch("auth/users/accept-tos", { accepted_tos: true });

        // Update the user object in Vuex
        this.$store.commit("auth/updateUser", { accepted_tos: true });
        // Close the TOS dialog
        this.ToSDialog.show = false;
      } catch (error) {
        console.error("Failed to update TOS acceptance status:", error);
      }
    },
    declineTos() {
      // Handle TOS decline (e.g., log out the user or show a warning)
      this.ToSDialog.show = false;
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    }
  },

  watch: {
    isAuth(value) {
      this.$store.commit("viewer/initViewerSessionId");
      if (value) {
        this.loadPredictors();
        this.loadUserSettings();
        this.connectWebsocket();

        // Show TOS dialog if the user hasn't accepted it yet
        if (!this.hasAcceptedTos) {
          this.ToSDialog.show = true;
        }
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/utils.scss";
@import "@/styles/fonts.scss";
@import "@/styles/main.scss";

.tos-content {
  margin: 1rem 0;

  .tos-scrollable {
    max-height: 300px;
    overflow-y: auto;
    padding: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    white-space: pre-wrap;
  }
}
</style>
