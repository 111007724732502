<template>
  <div class="container mt-5">
    <div v-if="!showPurchase">
      <h2 class="text-center font-weight-bold">Manage Subscription</h2>
      <p class="text-center text-muted">Choose the plan that works for you</p>

      <!-- Top Section: Plan Name & Buttons -->
      <div class="d-flex justify-content-between align-items-center mb-3">
        <div class="d-flex align-items-center">
          <h3 class="font-weight-bold mb-0">
            Your {{ user.subscription_tier?.toUpperCase() }} Plan:

            <!-- <span
          :class="{
            'badge-success': user.subscription_status === 'active',
            'badge-warning': user.subscription_status === 'past_due',
            'badge-danger': user.subscription_status === 'canceled'
          }"
          >class="badge ml-3" -->
            {{ user.subscription_status.toUpperCase() }}
            <!-- </span> -->
          </h3>
        </div>

        <div class="d-flex ml-auto">
          <button
            class="btn btn-danger mr-2 btn-block"
            @click="openBillingPortal"
          >
            Cancel Plan
          </button>
          <button
            class="btn btn-secondary btn-block"
            @click="openBillingPortal"
          >
            Change Plan
          </button>
        </div>
      </div>

      <!-- Subscription Details -->
      <div class="card shadow-sm p-4" v-if="!loading">
        <div class="row">
          <!-- Usage Details -->
          <div class="col-md-4">
            <div class="border p-3 rounded bg-light shadow-sm">
              <h5 class="font-weight-bold text-primary">Usage Details</h5>
              <hr />

              <!-- Memory Section -->
              <v-card-subtitle class="mt-3">Memory Usage</v-card-subtitle>
              <v-card-text>
                <p>
                  <strong>Included:</strong>
                  {{ formatGB(usageDetails.memoryIncluded) }} GB /
                  <strong>Total:</strong>
                  {{ formatGB(usageDetails.memoryTotal) }} GB
                </p>
                <p>
                  <strong>Bonus:</strong>
                  {{ formatGB(usageDetails.memoryBonus) }} GB
                </p>
                <p>
                  <strong>Used:</strong>
                  {{ formatGB(usageDetails.memoryUsed) }} GB
                </p>

                <!-- Memory Usage Progress Bar -->
                <v-progress-linear
                  v-if="usageDetails.memoryTotal !== '0.00'"
                  :value="usageDetails.memoryUsagePercentage"
                  color="green"
                  height="20"
                  class="mt-2"
                >
                  <strong>{{ usageDetails.memoryUsagePercentage }}%</strong>
                </v-progress-linear></v-card-text
              >

              <!-- Sharing Links Section -->
              <!-- <v-card-subtitle class="mt-3">Sharing Links</v-card-subtitle>
              <v-card-text>
                <p>
                  <strong>Included:</strong> {{ usageDetails.linksIncluded }} /
                  <strong>Total:</strong> {{ usageDetails.linksTotal }}
                </p>
                <p><strong>Bonus:</strong> {{ usageDetails.linksBonus }}</p>

                Vuetify Progress Bar for Links
               <v-progress-linear
                  v-if="usageDetails.linksTotal > 0"
                  :value="usageDetails.linkUsagePercentage"
                  color="blue"
                  height="20"
                  class="mt-2"
                >
                  <strong>{{ usageDetails.linkUsagePercentage }}%</strong>
                </v-progress-linear>
              </v-card-text> -->

              <!-- Optional: Button to Buy More Memory -->
              <!-- <button class="btn btn-dark btn-block mt-3">Buy More Memory</button> -->
            </div>
          </div>

          <!-- Features Section -->
          <div class="col-md-4">
            <div class="border p-3 rounded">
              <h5 class="font-weight-bold">Plan Features</h5>
              <ul class="list-unstyled">
                <li v-for="(feature, index) in planFeatures" :key="index">
                  ✔ {{ feature }}
                </li>
              </ul>
            </div>
          </div>

          <!-- Billing Section -->
          <div class="col-md-4">
            <div class="border p-3 rounded">
              <h5 class="font-weight-bold">Billing & Payment</h5>
              <p>
                <strong>Price:</strong> ${{ billing.price }} /
                {{ billing.interval === "Monthly" ? "mo" : "year" }}
              </p>
              <p><strong>Billing period:</strong> {{ billing.interval }}</p>
              <p><strong>Renewal date:</strong> {{ billing.renewalDate }}</p>
              <hr />
              <button
                class="btn btn-dark btn-block mb-2"
                @click="openBillingPortal"
              >
                Edit Billing
              </button>
              <button class="btn btn-dark btn-block" @click="openBillingPortal">
                View Invoices
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Loading & Error State -->
      <div v-if="loading" class="text-center mt-4">
        <span class="spinner-border text-primary"></span> Loading subscription
        details...
      </div>
      <div v-if="error" class="alert alert-danger text-center mt-4">
        {{ error }}
      </div>
    </div>
    <PurchaseSubscription
      v-if="showPurchase"
      @close="handleClosePurchase()"
      :currentPriceId="currentPriceId"
      :subscriptionTier="user.subscription_tier"
    />
  </div>
</template>

<script>
import Vue from "vue";
import PurchaseSubscription from "@/components/PurchaseSubscription.vue";
import { EventBus } from "@/js/eventBus";

export default {
  components: { PurchaseSubscription },
  props: {
    showPurchase: { type: Boolean }
  },
  data() {
    return {
      // showPurchase: false,
      loading: true,
      error: null,
      user: this.$store.state.auth.user, // Get user from Vuex store
      currentPriceId: null,
      usageDetails: {
        included: 0,
        total: 0,
        rollover: 0,
        bonus: 0
      },
      plans: [
        {
          name: "Basic Plan",
          priceMonthly: 10,
          priceYearly: 8,
          stripePriceIdMonthly:
            window.location.hostname !== "localhost"
              ? "price_1QrNWjP21DGvyqlHy6r7gNxy"
              : "price_1QrRvOP21DGvyqlHdKB8LBYh",
          stripePriceIdYearly:
            window.location.hostname !== "localhost"
              ? "price_1QtYdLP21DGvyqlH94MBbfvj"
              : "price_1Qri3QP21DGvyqlH3RbAfH3I",
          colorClass: "text-success",
          buttonClass: "btn-success",
          features: [
            "Standard email support",
            "2GB secure cloud storage",
            "5 monthly share links",
            "5 monthly drop links",
            "Automated data anonymization",
            "Comprehensive patient & study dashboards",
            "Measurement tools & annotations",
            "Multi-Planar Reconstruction (MPR) visualization",
            "Interactive volume rendering"
          ]
        },
        {
          name: "Pro Plan",
          priceMonthly: 60,
          priceYearly: 48,
          stripePriceIdMonthly:
            window.location.hostname !== "localhost"
              ? "price_1QtX6TP21DGvyqlHPcEEzTFa"
              : "price_1QtX8rP21DGvyqlHvsznqBLh",
          stripePriceIdYearly:
            window.location.hostname !== "localhost"
              ? "price_1QtX70P21DGvyqlHxOwnRMkl"
              : "price_1QtX9SP21DGvyqlHSZu29pE8",
          colorClass: "text-warning",
          buttonClass: "btn-warning",
          features: [
            "All Basic Plan features",
            "Priority support",
            "50GB storage",
            "100 monthly share links",
            "100 monthly drop links",
            "Remote PACS integration",
            "Segmentation masks support",
            "3D surfaces support",
            "Export masks and surfaces for training datasets",
            "Integrate your AI server (get in touch with our sales team at sales@dicom.vision first)"
          ]
        }
      ],
      planFeatures: [],
      billing: {
        price: 0,
        renewalDate: "N/A"
      }
    };
  },
  methods: {
    openPurchaseDialog() {
      this.showPurchase = true; // Show the Purchase Dialog
    },
    handleClosePurchase() {
      this.showPurchase = false; // Hide Purchase Dialog
    },
    formatDate(timestamp) {
      if (!timestamp) return "N/A";
      return new Date(timestamp * 1000).toLocaleString(undefined, {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit"
      });
    },
    formatGB(bytes) {
      if (typeof bytes !== "number" || isNaN(bytes) || bytes <= 0)
        return "0.00"; // Ensure valid input
      return `${(bytes / 1024 ** 3).toFixed(2)}`; // Convert bytes → GB with 2 decimal places
    },

    async fetchSubscriptionDetails() {
      try {
        this.loading = true;
        const response = await Vue.$http.get("api/subscription-details");
        const data = response;

        this.user.subscription_tier = data.subscription_tier;
        this.user.subscription_status = data.status;
        console.log("subscription-details", data);
        this.currentPriceId = data.price_id;

        // Update billing details
        this.billing.price = data.price;
        this.billing.billingPeriod = this.formatDate(data.billing_period);
        this.billing.renewalDate = this.formatDate(data.renewal_date);
        this.billing.interval =
          data.billing_interval === "month" ? "Monthly" : "Yearly";

        // Update usage details (if applicable)
        if (!data || Object.keys(data).length === 0) {
          console.warn("[WARNING] No subscription details received.");
          this.loading = false;
          return;
        }

        console.log("[RECEIVED] Subscription details:", data);

        // Ensure numerical values before formatting
        const memoryIncludedRaw = data.memory_included || 0;
        const memoryBonusRaw = data.memory_bonus || 0;
        const memoryUsedRaw = data.memory_used || 0;

        // Directly store raw values, formatting is done when displaying
        this.usageDetails.memoryIncluded = memoryIncludedRaw;
        this.usageDetails.memoryBonus = memoryBonusRaw;
        this.usageDetails.memoryUsed = memoryUsedRaw;
        this.usageDetails.memoryTotal = memoryIncludedRaw + memoryBonusRaw;

        // Ensure valid percentage calculation
        this.usageDetails.memoryUsagePercentage =
          this.usageDetails.memoryTotal > 0
            ? (
                (this.usageDetails.memoryUsed / this.usageDetails.memoryTotal) *
                100
              ).toFixed(2)
            : 0;

        const selectedPlan = this.plans.find(
          plan =>
            plan.stripePriceIdMonthly === this.currentPriceId ||
            plan.stripePriceIdYearly === this.currentPriceId
        );

        this.planFeatures = selectedPlan ? selectedPlan.features : [];

        this.loading = false;
      } catch (err) {
        console.log(err);
        console.error("Failed to fetch subscription details.");
        this.loading = false;
      }
    },
    async cancelPlan() {
      try {
        // TODO ask for hard confirmation (like write "cancel subscription")
        const response = await this.$http.post("/api/cancel-subscription"); // Use 'this' instead of 'Vue'

        if (response.status === 200) {
          // Update Vuex store after cancellation
          this.user.subscription_status = "canceled";
          this.user.subscription_tier = "free"; // Reset to free tier

          alert("Your subscription has been canceled.");
        } else {
          alert("Failed to cancel subscription. Please try again.");
        }
      } catch (error) {
        console.error("Error canceling subscription:", error);
        alert("An error occurred while canceling the subscription."); // TODO show a dialog instead
      }
    },
    changePlan() {
      // alert("Change Plan clicked!");
      this.showPurchase = true;
    },
    async openBillingPortal() {
      try {
        const response = await this.$http.post("api/billing-portal", {
          customerId: this.user.stripeCustomerId
        });

        if (response.data.url) {
          window.location.href = response.data.url; // Redirect to Stripe billing portal
        } else {
          console.error("Failed to get billing URL");
        }
      } catch (error) {
        console.error("Billing portal error:", error);
      }
    }
  },
  mounted() {
    EventBus.$on("subscription-panel-opened", this.fetchSubscriptionDetails);
    this.fetchSubscriptionDetails();
  }
};
</script>

<style scoped>
.container {
  max-width: 900px;
}
.card {
  background: #fff;
  border-radius: 10px;
}
.border {
  border: 1px solid #ddd;
}
.btn-block {
  width: 100%;
}
.spinner-border {
  width: 2rem;
  height: 2rem;
}
</style>
