const termsOfService = `
DICOM VISION - TERMS OF SERVICE
Effective Date: 1st Jan 2025
Last Updated: 1st Jan 2025
1. ACCEPTANCE OF TERMS
1.1 Binding Agreement. By accessing, browsing, or using any portion of the DICOM Vision platform (the “Service”), you, the end user or entity you represent (collectively, “User” or “you”), acknowledge that you have read, understood, and agree to be legally bound by these Terms of Service (the “Terms”), our Privacy Policy, and any additional guidelines, policies, or rules posted on the Service or otherwise communicated to you by the Company.
1.2 No Use Without Agreement. IF YOU DO NOT AGREE TO ALL THE TERMS AND CONDITIONS SET FORTH HEREIN, YOU ARE PROHIBITED FROM ACCESSING OR USING THE SERVICE IN ANY MANNER.
1.3 Age and Authority. You represent that you are at least eighteen (18) years of age (or the applicable age of majority in your jurisdiction), and that you have the legal authority to accept these Terms on behalf of yourself and any entity you may represent in connection with your use of the Service.
1.4 Incorporation by Reference. All policies referenced in these Terms, including without limitation the Privacy Policy, any Data Processing Agreement, or other documents referenced herein or provided by the Company, are hereby incorporated by reference and form an integral part of these Terms.

2. DESCRIPTION OF THE SERVICE
2.1 SaaS Nature. DICOM Vision provides a software-as-a-service (SaaS) platform designed to assist healthcare organizations and professionals in performing quality assurance testing, validation, and analysis of digital medical imaging files, such as DICOM data (“User Data”).
2.2 No Guarantee of Availability. The Company reserves the right, at its sole discretion, to modify, discontinue, or terminate the Service or any features thereof at any time, with or without notice, and without liability to you.
2.3 No Medical Advice. You acknowledge and agree that the Service does not constitute the practice of medicine, nor does it provide medical advice, diagnoses, or treatment recommendations. All analyses, outputs, and information generated by the Service are for informational and administrative purposes only, and you bear sole responsibility for all clinical and diagnostic decisions made.

3. ACCOUNT REGISTRATION AND RESPONSIBILITIES
3.1 Registration Process. To access the Service, you may be required to register an account by providing accurate, current, and complete information as requested. You agree to promptly update your information in the event of any changes.
3.2 Credentials Confidentiality. You are solely responsible for maintaining the confidentiality of your account credentials (username, password, access tokens, etc.) and for all activities that occur under your account. You agree to immediately notify the Company of any suspected or actual unauthorized use of your account or any other breach of security.
3.3 Prohibited Account Sharing. You shall not share your account credentials with any other person or entity, except as expressly permitted by the Company, and you shall ensure that any individuals authorized to use your account on your behalf comply with these Terms in full.
3.4 Suspension/Termination of Account. The Company reserves the right to suspend, deactivate, or terminate any User’s access to the Service at its sole discretion, for any reason or no reason, including but not limited to violation of these Terms, without prior notice or liability.

4. LICENSE GRANT AND RESTRICTIONS
4.1 Limited License. Subject to your compliance with these Terms, the Company grants you a limited, non-exclusive, non-transferable, non-sublicensable, and revocable right and license to access and use the Service solely for your internal business or professional purposes.
4.2 Prohibited Actions. You shall not, and shall not permit any third party to:
(a) Reverse Engineer: Reverse engineer, decompile, disassemble, or otherwise attempt to discover the source code, object code, or underlying structure or algorithms of the Service (except to the extent applicable laws specifically prohibit such restriction).
(b) Modifications/Derivatives: Modify, translate, or create derivative works based on the Service, in whole or in part.
(c) Transfer/Resell: Resell, distribute, sublicense, lease, rent, or otherwise transfer the Service to any third party without the Company’s prior written consent.
(d) Unauthorized Access: Use the Service in a manner that disrupts the integrity, security, or performance of the Service, or attempt to gain unauthorized access to any systems or networks related to the Service.
(e) Illegal or Harmful Activities: Use the Service for any illegal, fraudulent, defamatory, obscene, or otherwise harmful or unlawful activity.
4.3 Reservation of Rights. The Company retains all rights, title, and interest in and to the Service, including all related intellectual property rights not expressly granted herein.

5. USER DATA AND PRIVACY OBLIGATIONS
5.1 Ownership of User Data. You retain all right, title, and interest in and to any data, including but not limited to DICOM files, images, or other information you upload or transmit through the Service (collectively, “User Data”), except as expressly granted herein.
5.2 License to User Data. By uploading or submitting User Data to the Service, you grant the Company a worldwide, non-exclusive, royalty-free, fully-paid, transferable, and sublicensable license to host, store, process, transfer, display, analyze, and otherwise use your User Data as necessary to (a) provide and improve the Service, (b) comply with applicable laws or regulations, and (c) enforce these Terms.
5.3 Compliance with Law. You are solely responsible for ensuring that the submission, collection, and use of User Data in connection with the Service complies with all applicable laws, regulations, and industry standards, including but not limited to HIPAA (USA), GDPR (EU), and any other healthcare or data protection regulations.
5.4 Sensitive Information. You represent and warrant that you have obtained all necessary authorizations and consents to upload or process sensitive information (including Protected Health Information) via the Service, and that your use of such information is lawful.
5.5 Data Security. The Company uses commercially reasonable administrative, technical, and physical safeguards to protect User Data. HOWEVER, NO METHOD OF STORAGE OR TRANSMISSION IS 100% SECURE, AND THE COMPANY DOES NOT GUARANTEE ABSOLUTE SECURITY. YOU AGREE THAT THE COMPANY SHALL NOT BE LIABLE FOR ANY UNAUTHORIZED ACCESS TO OR ALTERATION OF USER DATA, EXCEPT TO THE EXTENT SUCH LIABILITY CANNOT BE EXCLUDED BY LAW OR IS CAUSED BY THE COMPANY’S GROSS NEGLIGENCE OR WILLFUL MISCONDUCT.

6. PAYMENT TERMS AND BILLING
6.1 Fees. You agree to pay all fees associated with your subscription or usage of the Service, as specified in your plan or purchase order. All fees are due and payable in advance and are non-refundable unless expressly stated otherwise herein.
6.2 Payment Authorization. You authorize the Company (and any third-party payment processor designated by the Company) to charge your provided payment method for all fees incurred in connection with your account.
6.3 Late Payments. If any amounts due are not received by the Company by the due date, then at the Company’s discretion, (a) such charges may accrue late interest at the rate of one and one-half percent (1.5%) per month, or the maximum rate permitted by law, whichever is lower; and/or (b) the Company may suspend provision of the Service to you until such delinquent amounts are paid in full.
6.4 Taxes. You are responsible for paying all sales, use, value-added, or other applicable taxes (excluding taxes based upon the Company’s net income) that may arise in connection with your use of the Service.

7. DISCLAIMER OF WARRANTIES
7.1 As Is, As Available. THE SERVICE, INCLUDING ALL CONTENT, FEATURES, AND FUNCTIONALITY PROVIDED THEREIN, IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT WARRANTY OF ANY KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY.
7.2 No Warranty. THE COMPANY AND ITS AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, LICENSORS, AND SERVICE PROVIDERS (COLLECTIVELY, THE “COMPANY PARTIES”) EXPRESSLY DISCLAIM ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, AND TITLE.
7.3 No Guarantee of Accuracy or Results. THE COMPANY DOES NOT WARRANT THAT THE SERVICE WILL BE ERROR-FREE, ACCURATE, COMPLETE, OR MEET YOUR SPECIFIC REQUIREMENTS. THE COMPANY ALSO DOES NOT GUARANTEE THAT THE SERVICE WILL IDENTIFY OR CORRECT ANY OR ALL DEFECTS, ERRORS, OR INCONSISTENCIES IN USER DATA.
7.4 No Medical Advice or Diagnosis. YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT THE SERVICE DOES NOT PROVIDE MEDICAL ADVICE, DIAGNOSIS, OR TREATMENT. THE COMPANY ASSUMES NO RESPONSIBILITY FOR ANY CONSEQUENCES RELATING DIRECTLY OR INDIRECTLY TO ANY ACTION OR INACTION YOU TAKE BASED ON THE SERVICE.

8. LIMITATION OF LIABILITY
8.1 Indirect Damages. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL THE COMPANY PARTIES BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES, NOR FOR ANY LOSS OF PROFITS, REVENUE, DATA, BUSINESS, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
8.2 Liability Cap. TO THE EXTENT PERMITTED BY APPLICABLE LAW, THE COMPANY PARTIES’ TOTAL CUMULATIVE LIABILITY FOR ANY CLAIMS ARISING OUT OF OR RELATED TO THESE TERMS OR THE SERVICE SHALL NOT EXCEED THE AMOUNT PAID BY YOU TO THE COMPANY FOR THE SERVICE IN THE TWELVE (12) MONTHS PRECEDING THE EVENT GIVING RISE TO THE CLAIM.
8.3 Applicability. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR LIMITATION OF LIABILITY FOR CERTAIN TYPES OF DAMAGES. IN SUCH JURISDICTIONS, THE COMPANY PARTIES’ LIABILITY SHALL BE LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.
8.4 Allocation of Risk. THE FOREGOING LIMITATIONS AND EXCLUSIONS ARE AN ESSENTIAL PART OF THESE TERMS AND FORM THE BASIS FOR DETERMINING THE FEES CHARGED FOR THE SERVICE, IF ANY. YOU ACKNOWLEDGE AND AGREE THAT THE COMPANY WOULD NOT ENTER INTO THESE TERMS OR PROVIDE THE SERVICE WITHOUT THESE LIMITATIONS.

9. INDEMNIFICATION
9.1 User Indemnity. You agree to defend, indemnify, and hold harmless the Company Parties from and against any and all claims, losses, damages, liabilities, costs, and expenses (including reasonable attorneys’ fees) arising out of or related to:
(a) Your use or misuse of the Service;
(b) Any breach or alleged breach of these Terms by you;
(c) Your violation of any law or regulation, or the rights of any third party, including privacy or intellectual property rights;
(d) The Company’s use of User Data in accordance with these Terms;
(e) Your negligence or willful misconduct.
9.2 Indemnification Procedure. The Company will (i) promptly notify you of any claim in writing, (ii) allow you to control the defense or settlement of such claim, and (iii) reasonably cooperate with any defense or settlement by you, provided that you shall not enter into any settlement without the Company’s prior written consent if such settlement would impose any liability or admission on the Company.

10. TERM AND TERMINATION
10.1 Term. These Terms shall commence on the date you first access or use the Service and continue until terminated as set forth herein or as otherwise stated in any order form, subscription plan, or agreement you have with the Company.
10.2 Termination by User. You may terminate these Terms or your subscription at any time by providing written notice to the Company (or by following any account termination procedure set forth in the Service). However, no refunds will be provided for any prepaid fees, except as may be required by applicable law.
10.3 Termination or Suspension by the Company. The Company may terminate or suspend your account, subscription, or access to the Service immediately, without prior notice or liability, if you breach any provision of these Terms or if the Company determines, at its sole discretion, that your use of the Service may cause liability or reputational harm to the Company.
10.4 Effect of Termination. Upon termination of these Terms for any reason:
(a) All licenses granted hereunder shall immediately cease;
(b) You shall cease all use of the Service;
(c) The Company may retain User Data as required by applicable law or internal policies, subject to the Company’s Privacy Policy;
(d) Any provisions that by their nature should survive termination (including, without limitation, disclaimers of warranties, limitations of liability, and indemnification) shall survive.

11. GOVERNING LAW AND DISPUTE RESOLUTION
11.1 Governing Law. These Terms and any action related thereto shall be governed by and construed under the laws of [Insert Governing Jurisdiction], without regard to its conflict of law principles.
11.2 Arbitration. Any dispute arising out of or relating to these Terms, including the validity, breach, termination, or interpretation thereof, shall be finally settled by binding arbitration administered by [Insert Arbitration Body] under its rules then in effect. The seat of arbitration shall be [Insert Location], and the arbitration shall be conducted in [Insert Language]. Judgment on the arbitral award may be entered in any court having jurisdiction thereof.
11.3 Waiver of Class Actions. You acknowledge and agree that you will resolve any disputes on an individual basis, and that any claims brought under these Terms will be brought in your individual capacity, and not as a plaintiff or class member in any purported class or representative proceeding.

12. CONFIDENTIALITY
12.1 Confidential Information. You may receive or have access to confidential or proprietary information of the Company, including but not limited to pricing information, technical data, trade secrets, business strategies, and customer lists (“Confidential Information”). You agree to maintain the confidentiality of all such Confidential Information and to use it only for the purpose of using the Service in accordance with these Terms.
12.2 Exclusions. Confidential Information does not include information that is (a) publicly available through no fault of your own, (b) lawfully received from a third party without breach of any obligation of confidentiality, or (c) independently developed by you without use of or reference to the Company’s Confidential Information.
12.3 Mandatory Disclosure. If you are compelled by law, regulation, or court order to disclose Confidential Information, you shall provide the Company with prompt written notice of such requirement, to the extent permitted by law, so that the Company may seek a protective order or other appropriate remedy.

13. CHANGES TO TERMS
13.1 Modifications. The Company reserves the right, at its sole discretion, to amend or modify these Terms at any time. If the Company makes material changes to these Terms, it will provide notice (e.g., by email to the address associated with your account or by posting a prominent notice on the Service).
13.2 Acceptance of Modified Terms. Your continued use of the Service following the posting or notice of any changes to these Terms constitutes your acceptance of those changes. If you do not agree to any such changes, your sole and exclusive remedy is to discontinue use of the Service.

14. FORCE MAJEURE
14.1 No Liability for Force Majeure. The Company shall not be liable for any delay or failure to perform its obligations under these Terms if such delay or failure is due to any cause beyond its reasonable control, including but not limited to acts of God, war, riots, embargoes, strikes, natural disasters, pandemics, power outages, internet disturbances, or governmental actions (“Force Majeure Event”).
14.2 Notification. The Company shall provide notice to you of a Force Majeure Event and make commercially reasonable efforts to mitigate its effect.

15. MISCELLANEOUS
15.1 Relationship of Parties. Nothing in these Terms shall be construed as creating any agency, partnership, joint venture, fiduciary, or other form of joint enterprise between the parties, and neither party has the authority to contract for or bind the other party in any manner whatsoever.
15.2 Assignment. You may not assign or transfer these Terms or any rights or obligations hereunder, whether by operation of law or otherwise, without the prior written consent of the Company. Any attempted assignment or transfer in violation of this section shall be null and void. The Company may freely assign or transfer these Terms at its discretion without notice.
15.3 Entire Agreement. These Terms, along with any order form, agreement, or additional policy incorporated herein, represent the entire agreement between you and the Company regarding the Service and supersede all prior or contemporaneous agreements, understandings, or representations, whether written or oral.
15.4 Severability. If any provision of these Terms is determined to be invalid or unenforceable under applicable law, such provision shall be deemed severed from these Terms and shall not affect the validity or enforceability of the remaining provisions, which shall remain in full force and effect.
15.5 No Waiver. The failure by the Company to enforce any right or provision of these Terms shall not constitute a waiver of future enforcement of that right or provision.
15.6 Notices. Except as otherwise provided herein, all notices or other communications required hereunder shall be in writing and delivered to the addresses or email addresses provided by the parties in connection with the Services. Notices to the Company shall be sent to:
DICOM Vision
Attn: Legal Department
Piazzale della Repubblica 2, Bergamo
Email: dicom.vision@pec.it

15.7 Headings. Headings and titles are provided for convenience only and shall not affect the interpretation of these Terms.

16. CONTACT INFORMATION
If you have any questions about these Terms, the Service, or need to contact us for any other reason, please reach out at:
DICOM Vision S.r.l.

Email: dicom.vision@pec.it
Address: Piazzale della Repubblica 2, Bergamo


BY CLICKING “I AGREE,” OR BY OTHERWISE MANIFESTING ASSENT TO THESE TERMS, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THESE TERMS OF SERVICE AND ALL INCORPORATED POLICIES AND DOCUMENTS.

Italiano:
DICOM VISION - TERMINI DI SERVIZIO
Data di efficacia: 1 Gennaio 2025
Ultimo aggiornamento: 1 Gennaio 2025

1. ACCETTAZIONE DEI TERMINI
1.1 Accordo vincolante. Accedendo, navigando o utilizzando qualsiasi parte della piattaforma DICOM Vision (il "Servizio"), l'utente finale o l'entità rappresentata (collettivamente, "Utente" o "tu") riconosce di aver letto, compreso e accettato di essere legalmente vincolato da questi Termini di Servizio (i "Termini"), dalla nostra Politica sulla Privacy e da eventuali linee guida, politiche o regole aggiuntive pubblicate sul Servizio o altrimenti comunicate dalla Società.
1.2 Divieto di utilizzo senza accettazione. SE NON ACCETTI TUTTI I TERMINI E LE CONDIZIONI QUI STABILITI, TI È VIETATO ACCEDERE O UTILIZZARE IL SERVIZIO IN QUALSIASI MODO.
1.3 Età e autorità. Dichiari di avere almeno diciotto (18) anni (o l'età legale nel tuo territorio) e di avere l'autorità legale per accettare questi Termini a nome tuo e di qualsiasi entità che rappresenti in connessione con l'uso del Servizio.
1.4 Incorporazione per riferimento. Tutte le politiche di cui si fa riferimento in questi Termini, inclusa, senza limitazione, la Politica sulla Privacy, qualsiasi Accordo di Elaborazione Dati o altri documenti qui menzionati o forniti dalla Società, sono incorporati per riferimento e costituiscono parte integrante di questi Termini.

2. DESCRIZIONE DEL SERVIZIO
2.1 Natura SaaS. DICOM Vision fornisce una piattaforma software-as-a-service (SaaS) progettata per assistere organizzazioni e professionisti sanitari nell'esecuzione di test di controllo qualità, validazione e analisi di file di imaging medico digitale, come i dati DICOM ("Dati Utente").
2.2 Nessuna garanzia di disponibilità. La Società si riserva il diritto, a sua esclusiva discrezione, di modificare, interrompere o terminare il Servizio o qualsiasi sua funzionalità in qualsiasi momento, con o senza preavviso, e senza alcuna responsabilità verso di te.
2.3 Nessun consiglio medico. Riconosci e accetti che il Servizio non costituisce pratica medica, né fornisce consigli, diagnosi o raccomandazioni di trattamento. Tutte le analisi, gli output e le informazioni generate dal Servizio sono solo a scopo informativo e amministrativo, e sei l'unico responsabile di tutte le decisioni cliniche e diagnostiche prese.

3. REGISTRAZIONE DELL'ACCOUNT E RESPONSABILITÀ
3.1 Processo di registrazione. Per accedere al Servizio, potrebbe essere necessario registrare un account fornendo informazioni accurate, aggiornate e complete come richiesto. Ti impegni a aggiornare tempestivamente le tue informazioni in caso di modifiche.
3.2 Riservatezza delle credenziali. Sei l'unico responsabile della riservatezza delle credenziali del tuo account (nome utente, password, token di accesso, ecc.) e di tutte le attività che si verificano sotto il tuo account. Ti impegni a notificare immediatamente alla Società qualsiasi uso non autorizzato sospetto o effettivo del tuo account o qualsiasi altra violazione della sicurezza.
3.3 Divieto di condivisione dell'account. Non devi condividere le tue credenziali con altre persone o entità, salvo espressa autorizzazione della Società, e devi garantire che eventuali individui autorizzati a utilizzare il tuo account per tuo conto rispettino integralmente questi Termini.
3.4 Sospensione/Chiusura dell'account. La Società si riserva il diritto di sospendere, disattivare o chiudere l'accesso di qualsiasi Utente al Servizio a sua esclusiva discrezione, per qualsiasi motivo o senza motivo, incluso, ma non limitato a, la violazione di questi Termini, senza preavviso o responsabilità.

4. CONCESSIONE DI LICENZA E RESTRIZIONI
4.1 Licenza limitata. Fatto salvo il rispetto di questi Termini, la Società ti concede un diritto e una licenza limitati, non esclusivi, non trasferibili, non sublicenziabili e revocabili per accedere e utilizzare il Servizio esclusivamente per scopi aziendali o professionali interni.
4.2 Azioni vietate. Non devi, né permettere a terzi di:
(a) Ingegneria inversa: Eseguire operazioni di ingegneria inversa, decompilare, disassemblare o tentare di scoprire il codice sorgente, il codice oggetto o la struttura sottostante o gli algoritmi del Servizio (salvo nei casi in cui le leggi applicabili lo vietino espressamente).
(b) Modifiche/Derivati: Modificare, tradurre o creare opere derivate basate sul Servizio, in tutto o in parte.
(c) Trasferimento/Rivendita: Rivendere, distribuire, sublicenziare, affittare o trasferire il Servizio a terzi senza il consenso scritto preventivo della Società.
(d) Accesso non autorizzato: Utilizzare il Servizio in modo da compromettere l'integrità, la sicurezza o le prestazioni del Servizio, o tentare di ottenere l'accesso non autorizzato a sistemi o reti correlati al Servizio.
(e) Attività illegali o dannose: Utilizzare il Servizio per attività illegali, fraudolente, diffamatorie, oscene o comunque dannose o illecite.
4.3 Riserva dei diritti. La Società conserva tutti i diritti, titoli e interessi relativi al Servizio, inclusi tutti i diritti di proprietà intellettuale non espressamente concessi in questi Termini.

5. DATI UTENTE E OBBLIGHI DI PRIVACY
5.1 Proprietà dei dati utente. Conservi tutti i diritti, titoli e interessi relativi ai dati, inclusi, ma non limitati a, file DICOM, immagini o altre informazioni caricate o trasmesse tramite il Servizio (collettivamente, "Dati Utente"), salvo quanto espressamente concesso in questi Termini.
5.2 Licenza sui dati utente. Caricando o inviando Dati Utente al Servizio, concedi alla Società una licenza mondiale, non esclusiva, esente da royalty, completamente pagata, trasferibile e sublicenziabile per ospitare, memorizzare, elaborare, trasferire, visualizzare, analizzare e utilizzare i tuoi Dati Utente secondo quanto necessario per (a) fornire e migliorare il Servizio, (b) conformarsi alle leggi applicabili e (c) far rispettare questi Termini.
5.3 Conformità alla legge. Sei l'unico responsabile di garantire che l'invio, la raccolta e l'uso dei Dati Utente in connessione con il Servizio siano conformi a tutte le leggi, regolamenti e standard del settore applicabili, inclusi, ma non limitati a, HIPAA (USA), GDPR (UE) e qualsiasi altra normativa sanitaria o di protezione dei dati.
5.4 Informazioni sensibili. Dichiari e garantisci di aver ottenuto tutte le autorizzazioni e i consensi necessari per caricare o elaborare informazioni sensibili (inclusi i Dati Sanitari Protetti) tramite il Servizio e che il loro utilizzo è legale.
5.5 Sicurezza dei dati. La Società utilizza misure di sicurezza amministrative, tecniche e fisiche commercialmente ragionevoli per proteggere i Dati Utente. TUTTAVIA, NESSUN METODO DI ARCHIVIAZIONE O TRASMISSIONE È AL 100% SICURO, E LA SOCIETÀ NON GARANTISCE UNA SICUREZZA ASSOLUTA. ACCETTI CHE LA SOCIETÀ NON SARÀ RESPONSABILE PER QUALSIASI ACCESSO NON AUTORIZZATO O ALTERAZIONE DEI DATI UTENTE, SALVO NEI CASI IN CUI TALE RESPONSABILITÀ NON SIA ESCLUSA DALLA LEGGE O SIA DOVUTA A NEGLIGENZA GRAVE O DOLO DELLA SOCIETÀ.

6. TERMINI DI PAGAMENTO E FATTURAZIONE
6.1 Tariffe. Accetti di pagare tutte le tariffe associate al tuo abbonamento o utilizzo del Servizio, come specificato nel tuo piano o ordine di acquisto. Tutte le tariffe sono dovute e pagabili in anticipo e non sono rimborsabili, salvo diversamente indicato.
6.2 Autorizzazione al pagamento. Autorizzi la Società (e qualsiasi processore di pagamento di terze parti designato dalla Società) ad addebitare il metodo di pagamento fornito per tutte le tariffe relative al tuo account.
6.3 Pagamenti ritardati. Se gli importi dovuti non vengono ricevuti dalla Società entro la scadenza, a discrezione della Società, (a) tali importi potranno maturare interessi di mora all'1,5% al mese, o al tasso massimo consentito dalla legge, se inferiore; e/o (b) la Società potrà sospendere la fornitura del Servizio fino al pagamento integrale degli importi dovuti.
6.4 Tasse. Sei responsabile del pagamento di tutte le tasse di vendita, uso, valore aggiunto o altre imposte applicabili (escluse le tasse basate sul reddito netto della Società) che possano sorgere in connessione con l'uso del Servizio.

7. ESCLUSIONE DI GARANZIE
7.1 Fornitura "Così Com'è" e "Come Disponibile". IL SERVIZIO, COMPRESI TUTTI I CONTENUTI, LE FUNZIONALITÀ E LE CARATTERISTICHE FORNITE, VIENE FORNITO "COSÌ COM'È" E "COME DISPONIBILE" SENZA ALCUNA GARANZIA, ESPRESSA, IMPLICITA O PREVISTA DALLA LEGGE.

7.2 Assenza di Garanzia. LA SOCIETÀ E LE SUE AFFILIATE, DIRIGENTI, AMMINISTRATORI, DIPENDENTI, AGENTI, LICENZIATARI E FORNITORI DI SERVIZI (COLLETTIVAMENTE, LE "PARTI DELLA SOCIETÀ") DECLINANO ESPRESSAMENTE OGNI GARANZIA, ESPRESSA, IMPLICITA O PREVISTA DALLA LEGGE, INCLUSE, A TITOLO ESEMPLIFICATIVO, LE GARANZIE IMPLICITE DI COMMERCIABILITÀ, IDONEITÀ PER UNO SCOPO PARTICOLARE, NON VIOLAZIONE E TITOLARITÀ.

7.3 Nessuna Garanzia di Accuratezza o Risultati. LA SOCIETÀ NON GARANTISCE CHE IL SERVIZIO SARÀ PRIVO DI ERRORI, ACCURATO, COMPLETO O IDONEO ALLE TUE ESIGENZE SPECIFICHE. LA SOCIETÀ NON GARANTISCE NEMMENO CHE IL SERVIZIO IDENTIFICHI O CORREGGA QUALSIASI DIFETTO, ERRORE O INCOERENZA NEI DATI DELL'UTENTE.

7.4 Nessun Consiglio o Diagnosi Medica. RICONOSCI ESPRESSAMENTE CHE IL SERVIZIO NON FORNISCE CONSIGLI MEDICI, DIAGNOSI O TRATTAMENTI. LA SOCIETÀ NON SI ASSUME ALCUNA RESPONSABILITÀ PER QUALSIASI CONSEGUENZA DERIVANTE DIRETTAMENTE O INDIRETTAMENTE DA QUALSIASI AZIONE O MANCATA AZIONE CHE PRENDI IN BASE AL SERVIZIO.

8. LIMITAZIONE DI RESPONSABILITÀ

8.1 Danni Indiretti. NELLA MISURA MASSIMA CONSENTITA DALLA LEGGE APPLICABILE, IN NESSUN CASO LE PARTI DELLA SOCIETÀ SARANNO RESPONSABILI PER DANNI INDIRETTI, INCIDENTALI, SPECIALI, CONSEQUENZIALI, ESEMPLARI O PUNITIVI, NÉ PER PERDITA DI PROFITTI, ENTRATE, DATI, ATTIVITÀ, UTILIZZO, AVVIAMENTO O ALTRI DANNI INTANGIBILI, ANCHE SE AVVISATE DELLA POSSIBILITÀ DI TALI DANNI.

8.2 Limite di Responsabilità. NELLA MISURA CONSENTITA DALLA LEGGE APPLICABILE, LA RESPONSABILITÀ CUMULATIVA DELLE PARTI DELLA SOCIETÀ PER QUALSIASI RECLAMO DERIVANTE DA O RELATIVO A QUESTI TERMINI O AL SERVIZIO NON SUPERERÀ L'IMPORTO PAGATO DALL'UTENTE ALLA SOCIETÀ PER IL SERVIZIO NEI DODICI (12) MESI PRECEDENTI L'EVENTO CHE HA DATO ORIGINE AL RECLAMO.

8.3 Applicabilità. ALCUNE GIURISDIZIONI NON CONSENTONO L'ESCLUSIONE DI ALCUNE GARANZIE O LA LIMITAZIONE DI RESPONSABILITÀ PER ALCUNI TIPI DI DANNI. IN TALI GIURISDIZIONI, LA RESPONSABILITÀ DELLE PARTI DELLA SOCIETÀ SARÀ LIMITATA NELLA MISURA MASSIMA CONSENTITA DALLA LEGGE.

8.4 Allocazione del Rischio. LE LIMITAZIONI E LE ESCLUSIONI SOPRACITATE SONO UNA PARTE ESSENZIALE DI QUESTI TERMINI E COSTITUISCONO LA BASE SU CUI SONO DETERMINATI GLI EVENTUALI COSTI DEL SERVIZIO. L'UTENTE RICONOSCE CHE LA SOCIETÀ NON FORNIREBBE IL SERVIZIO SENZA QUESTE LIMITAZIONI.

9. MANLEVA

9.1 Indennizzo da Parte dell'Utente. Accetti di difendere, indennizzare e tenere indenni le Parti della Società da qualsiasi reclamo, perdita, danno, responsabilità, costo e spesa (incluse ragionevoli spese legali) derivanti da o correlati a:
(a) Il tuo uso o uso improprio del Servizio;
(b) Qualsiasi violazione o presunta violazione di questi Termini da parte tua;
(c) La tua violazione di qualsiasi legge o regolamento, o dei diritti di terzi, inclusi diritti di privacy o proprietà intellettuale;
(d) L'uso dei dati dell'utente da parte della Società in conformità con questi Termini;
(e) La tua negligenza o condotta dolosa.

9.2 Procedura di Indennizzo. La Società (i) ti notificherà tempestivamente qualsiasi reclamo per iscritto, (ii) ti permetterà di controllare la difesa o la risoluzione di tale reclamo e (iii) collaborerà ragionevolmente con qualsiasi difesa o risoluzione da parte tua, a condizione che tu non possa concludere alcun accordo senza il consenso scritto della Società se tale accordo comporterebbe responsabilità o ammissioni a carico della Società.

10. DURATA E RISOLUZIONE
10.1 Durata. I presenti Termini hanno inizio alla data in cui accedi per la prima volta o utilizzi il Servizio e continueranno fino alla loro risoluzione come indicato nel presente documento o come diversamente stabilito in qualsiasi modulo d'ordine, piano di abbonamento o accordo con la Società.
10.2 Risoluzione da parte dell'Utente. Puoi risolvere i presenti Termini o il tuo abbonamento in qualsiasi momento fornendo una comunicazione scritta alla Società (o seguendo qualsiasi procedura di terminazione dell'account prevista dal Servizio). Tuttavia, non verranno forniti rimborsi per eventuali pagamenti anticipati, salvo quanto richiesto dalla legge applicabile.
10.3 Risoluzione o Sospensione da parte della Società. La Società può risolvere o sospendere il tuo account, abbonamento o accesso al Servizio immediatamente, senza preavviso o responsabilità, se violi qualsiasi disposizione dei presenti Termini o se la Società determina, a sua esclusiva discrezione, che il tuo utilizzo del Servizio possa causare responsabilità o danni reputazionali alla Società.
10.4 Effetti della Risoluzione. Alla risoluzione dei presenti Termini per qualsiasi motivo: (a) Tutte le licenze concesse in base a questi Termini cesseranno immediatamente; (b) Dovrai interrompere immediatamente l'uso del Servizio; (c) La Società potrà conservare i Dati dell'Utente come richiesto dalla legge applicabile o dalle proprie politiche interne, nel rispetto dell'Informativa sulla Privacy della Società; (d) Qualsiasi disposizione che per sua natura dovrebbe sopravvivere alla risoluzione (inclusi, a titolo esemplificativo, le esclusioni di garanzie, le limitazioni di responsabilità e le disposizioni di indennizzo) rimarrà in vigore.
11. LEGGE APPLICABILE E RISOLUZIONE DELLE CONTROVERSIE
11.1 Legge Applicabile. I presenti Termini e qualsiasi azione ad essi correlata saranno disciplinati e interpretati in conformità con le leggi di [Inserire Giurisdizione], senza riguardo ai suoi principi in materia di conflitti di legge.
11.2 Arbitrato. Qualsiasi controversia derivante dai presenti Termini o ad essi relativa, compresa la loro validità, violazione, risoluzione o interpretazione, sarà definitivamente risolta mediante arbitrato vincolante amministrato da [Inserire Ente Arbitrale] secondo le sue regole in vigore. La sede dell'arbitrato sarà [Inserire Luogo] e l'arbitrato sarà condotto in [Inserire Lingua]. Il lodo arbitrale potrà essere eseguito in qualsiasi tribunale avente giurisdizione.
11.3 Rinuncia alle Azioni Collettive. Riconosci e accetti che risolverai eventuali controversie su base individuale e che qualsiasi reclamo ai sensi dei presenti Termini sarà presentato nella tua capacità individuale, e non come attore o membro di una classe in qualsiasi presunta azione collettiva o rappresentativa.
12. RISERVATEZZA
12.1 Informazioni Riservate. Potresti ricevere o avere accesso a informazioni riservate o proprietarie della Società, comprese, a titolo esemplificativo, informazioni sui prezzi, dati tecnici, segreti commerciali, strategie aziendali ed elenchi di clienti ("Informazioni Riservate"). Accetti di mantenere la riservatezza di tutte le Informazioni Riservate e di utilizzarle esclusivamente ai fini dell'utilizzo del Servizio in conformità con i presenti Termini.
12.2 Esclusioni. Le Informazioni Riservate non includono informazioni che (a) sono pubblicamente disponibili senza colpa dell'utente, (b) sono state legalmente ricevute da una terza parte senza violazione di alcun obbligo di riservatezza, o (c) sono state sviluppate indipendentemente dall'utente senza utilizzare o fare riferimento alle Informazioni Riservate della Società.
12.3 Divulgazione Obbligatoria. Se sei obbligato per legge, regolamento o ordine del tribunale a divulgare Informazioni Riservate, dovrai fornire alla Società una tempestiva comunicazione scritta di tale obbligo, nella misura consentita dalla legge, in modo che la Società possa richiedere un'ingiunzione protettiva o altro rimedio appropriato.
13. MODIFICHE AI TERMINI
13.1 Modifiche. La Società si riserva il diritto, a sua esclusiva discrezione, di modificare o aggiornare i presenti Termini in qualsiasi momento. Se la Società apporta modifiche sostanziali a questi Termini, fornirà una notifica (ad esempio, via email all'indirizzo associato al tuo account o pubblicando un avviso ben visibile sul Servizio).
13.2 Accettazione delle Modifiche. Il tuo utilizzo continuato del Servizio dopo la pubblicazione o la notifica di eventuali modifiche ai presenti Termini costituisce la tua accettazione di tali modifiche. Se non sei d'accordo con tali modifiche, il tuo unico ed esclusivo rimedio è interrompere l'uso del Servizio.
14. FORZA MAGGIORE
14.1 Nessuna Responsabilità per Eventi di Forza Maggiore. La Società non sarà responsabile per eventuali ritardi o mancata esecuzione dei propri obblighi ai sensi dei presenti Termini se tali ritardi o mancate esecuzioni sono dovuti a cause al di fuori del suo ragionevole controllo, inclusi, a titolo esemplificativo, eventi naturali, guerre, rivolte, embarghi, scioperi, disastri naturali, pandemie, interruzioni di corrente, interruzioni di internet o azioni governative ("Evento di Forza Maggiore").
14.2 Notifica. La Società fornirà una notifica di un Evento di Forza Maggiore e adotterà sforzi commercialmente ragionevoli per mitigarne gli effetti.
15. VARIE
15.1 Relazione tra le Parti. Nulla in questi Termini deve essere interpretato come la creazione di un'agenzia, una partnership, una joint venture, un rapporto fiduciario o qualsiasi altra forma di impresa congiunta tra le parti. Nessuna delle parti ha l'autorità di stipulare contratti o vincolare l'altra in alcun modo.
15.2 Cessione. Non puoi cedere o trasferire questi Termini o qualsiasi diritto o obbligo qui previsto, sia per effetto di legge che in altro modo, senza il previo consenso scritto della Società. Qualsiasi tentativo di cessione o trasferimento in violazione di questa sezione sarà nullo e privo di effetto. La Società può liberamente cedere o trasferire questi Termini a sua discrezione senza preavviso.
15.3 Accordo Completo. Questi Termini, insieme a qualsiasi modulo d'ordine, accordo o politica aggiuntiva qui incorporata, rappresentano l'intero accordo tra te e la Società in relazione al Servizio e sostituiscono tutti gli accordi, intese o dichiarazioni precedenti o contemporanei, scritti o orali.
15.4 Clausola Salvatoria. Se una qualsiasi disposizione di questi Termini viene ritenuta non valida o inapplicabile ai sensi della legge applicabile, tale disposizione sarà considerata separabile dal resto dei Termini e non pregiudicherà la validità e l'applicabilità delle restanti disposizioni, che rimarranno in pieno vigore ed efficacia.
15.5 Nessuna Rinuncia. L'eventuale mancata applicazione da parte della Società di un diritto o di una disposizione di questi Termini non costituirà una rinuncia futura a tale diritto o disposizione.
15.6 Comunicazioni. Salvo quanto diversamente previsto nei presenti Termini, tutte le comunicazioni o altre notifiche richieste ai sensi del presente documento dovranno essere effettuate per iscritto e inviate agli indirizzi o alle e-mail fornite dalle parti in relazione al Servizio. Le notifiche alla Società dovranno essere inviate a:
DICOM Vision
Attn: Dipartimento Legale
Piazzale della Repubblica 2, Bergamo
Email: dicom.vision@pec.it
15.7 Intestazioni. I titoli e le intestazioni sono forniti solo per comodità e non influenzeranno l'interpretazione di questi Termini.

16. INFORMAZIONI DI CONTATTO
Se hai domande su questi Termini, sul Servizio o hai bisogno di contattarci per qualsiasi altro motivo, puoi farlo ai seguenti recapiti:
Email: dicom.vision@pec.it
Indirizzo: Piazzale della Repubblica 2, Bergamo

FACENDO CLIC SU “ACCETTO” O MANIFESTANDO ALTRIMENTI IL TUO CONSENSO A QUESTI TERMINI, RICONOSCI DI AVER LETTO, COMPRESO E ACCETTATO DI ESSERE VINCOLATO DA QUESTI TERMINI DI SERVIZIO E DA TUTTE LE POLITICHE E DOCUMENTI INCORPORATI.

`;

export default termsOfService;
