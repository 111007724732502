var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{style:({
    overflow: !_vm.$vuetify.breakpoint.smAndDown && 'hidden',
    maxHeight: !_vm.$vuetify.breakpoint.smAndDown && '100%'
  })},[_c('v-main',{staticClass:"black",style:({ maxHeight: !_vm.$vuetify.breakpoint.smAndDown && '100vh' })},[_c('div',{staticClass:"black main-box",class:{ 'd-flex': !_vm.$vuetify.breakpoint.smAndDown }},[_c('div',{staticClass:"auth-box white--text d-flex",class:{
          'justify-center': _vm.loginFormHidden,
          'pa-12': !_vm.loginFormHidden && !_vm.$vuetify.breakpoint.smAndDown
        },style:({
          height: _vm.$vuetify.breakpoint.smAndDown && '90vh',
          width: _vm.loginFormHidden
            ? '20%'
            : _vm.$vuetify.breakpoint.smAndDown
            ? '100vw'
            : '50%'
        })},[_c('div',{staticClass:"box black my-auto",class:{ 'mx-16': !_vm.$vuetify.breakpoint.smAndDown },style:({ margin: _vm.$vuetify.breakpoint.smAndDown && '0 5%' })},[_c('div',{staticClass:"d-flex justify-center black pb-4 image-container",style:({
              padding: _vm.$vuetify.breakpoint.mdAndDown
                ? _vm.$vuetify.breakpoint.smAndDown
                  ? '0 25%'
                  : _vm.loginFormHidden
                  ? '0 0'
                  : '0 5%'
                : '0 20%'
            })},[_c('img',{attrs:{"src":"/media/images/logo-dicom-black-trim.png","alt":""}})]),(!_vm.loginFormHidden || _vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"black pa-4",staticStyle:{"margin":"auto"},style:({
              width: _vm.$vuetify.breakpoint.mdAndDown ? '100%' : '500px'
            })},[_c('router-view',{on:{"request-demo":_vm.demoUserLogin}})],1):_vm._e(),(_vm.loginFormHidden && !_vm.$vuetify.breakpoint.smAndDown)?_c('v-btn',{attrs:{"block":"","color":"primary","dark":""},on:{"click":function($event){_vm.loginFormHidden = !_vm.loginFormHidden}}},[_vm._v(" LOGIN ")]):_vm._e()],1)]),(_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"white--text d-flex",staticStyle:{"height":"10vh"}},[_c('h3',{staticClass:"ma-auto"},[_vm._v("SCROLL DOWN TO IMPORT YOUR FILE")])]):_vm._e(),(!_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"d-flex justify-center flex-column pa-4"},[_c('v-divider',{staticClass:"border-opacity-100 divider",attrs:{"color":"white","thickness":"80","vertical":""}})],1):_vm._e(),_c('div',{staticClass:"auth-box d-flex justify-center flex-column",class:{ 'ma-auto': _vm.$vuetify.breakpoint.smAndDown },style:({
          width:
            _vm.loginFormHidden || _vm.$vuetify.breakpoint.smAndDown ? '80%' : '50%',
          padding: _vm.$vuetify.breakpoint.mdAndDown
            ? !_vm.$vuetify.breakpoint.smAndDown && '4vw 4vh'
            : '10vw 12vh',
          height: _vm.$vuetify.breakpoint.smAndDown && '90vh'
        })},[_c('div',{staticStyle:{"width":"100%","height":"100%"}},[_c('dicom-import',{attrs:{"dark":"","icon":"","options":_vm.importOptions,"label":""},on:{"dicom-import-open":_vm.openViewer,"dicom-import-upload":_vm.upload,"new-series-loaded":_vm.onNewSeriesLoaded}})],1)])]),_c('v-footer',{staticClass:"black white--text text-center d-flex flex-column"},[_c('div',{staticClass:"pt-0 text-subtitle-2"},[_vm._v(" "+_vm._s(_vm.$t("auth.noMDDisclaimer"))+" ")]),_c('v-divider'),_c('div',[_vm._v(" "+_vm._s(_vm.$t("auth.cookiePolicy"))+" "),_c('a',{attrs:{"href":"https://dicom.vision/web-privacy-policy-cookie","target":"_blank"}},[_vm._v("Cookie Policy")])]),_c('div',{staticClass:"text-caption"},[_c('strong',[_vm._v(_vm._s(new Date().getFullYear())+" — DICOM-VISION")])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }