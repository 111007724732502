<template>
  <v-app
    :style="{
      overflow: !$vuetify.breakpoint.smAndDown && 'hidden',
      maxHeight: !$vuetify.breakpoint.smAndDown && '100%'
    }"
  >
    <v-main
      class="black"
      :style="{ maxHeight: !$vuetify.breakpoint.smAndDown && '100vh' }"
    >
      <div
        class="black main-box"
        :class="{ 'd-flex': !$vuetify.breakpoint.smAndDown }"
      >
        <div
          class="auth-box white--text d-flex"
          :class="{
            'justify-center': loginFormHidden,
            'pa-12': !loginFormHidden && !$vuetify.breakpoint.smAndDown
          }"
          :style="{
            height: $vuetify.breakpoint.smAndDown && '90vh',
            width: loginFormHidden
              ? '20%'
              : $vuetify.breakpoint.smAndDown
              ? '100vw'
              : '50%'
          }"
        >
          <div
            class="box black my-auto"
            :class="{ 'mx-16': !$vuetify.breakpoint.smAndDown }"
            :style="{ margin: $vuetify.breakpoint.smAndDown && '0 5%' }"
          >
            <div
              class="d-flex justify-center black pb-4 image-container"
              :style="{
                padding: $vuetify.breakpoint.mdAndDown
                  ? $vuetify.breakpoint.smAndDown
                    ? '0 25%'
                    : loginFormHidden
                    ? '0 0'
                    : '0 5%'
                  : '0 20%'
              }"
            >
              <img src="/media/images/logo-dicom-black-trim.png" alt="" />
            </div>
            <div
              v-if="!loginFormHidden || $vuetify.breakpoint.smAndDown"
              class="black pa-4"
              :style="{
                width: $vuetify.breakpoint.mdAndDown ? '100%' : '500px'
              }"
              style="margin: auto"
            >
              <router-view @request-demo="demoUserLogin" />
            </div>
            <v-btn
              v-if="loginFormHidden && !$vuetify.breakpoint.smAndDown"
              block
              @click="loginFormHidden = !loginFormHidden"
              color="primary"
              dark
            >
              LOGIN
            </v-btn>
          </div>
        </div>
        <div
          v-if="$vuetify.breakpoint.smAndDown"
          class="white--text d-flex"
          style="height: 10vh"
        >
          <h3 class="ma-auto">SCROLL DOWN TO IMPORT YOUR FILE</h3>
        </div>
        <div
          v-if="!$vuetify.breakpoint.smAndDown"
          class="d-flex justify-center flex-column pa-4"
        >
          <v-divider
            class="border-opacity-100 divider"
            color="white"
            thickness="80"
            vertical
          ></v-divider>
        </div>
        <div
          class="auth-box d-flex justify-center flex-column"
          :class="{ 'ma-auto': $vuetify.breakpoint.smAndDown }"
          :style="{
            width:
              loginFormHidden || $vuetify.breakpoint.smAndDown ? '80%' : '50%',
            padding: $vuetify.breakpoint.mdAndDown
              ? !$vuetify.breakpoint.smAndDown && '4vw 4vh'
              : '10vw 12vh',
            height: $vuetify.breakpoint.smAndDown && '90vh'
          }"
        >
          <div class="" style="width: 100%; height: 100%">
            <dicom-import
              dark
              icon=""
              :options="importOptions"
              label=""
              @dicom-import-open="openViewer"
              @dicom-import-upload="upload"
              @new-series-loaded="onNewSeriesLoaded"
            ></dicom-import>
          </div>
        </div>
      </div>
      <v-footer class="black white--text text-center d-flex flex-column">
        <div class="pt-0 text-subtitle-2">
          {{ $t("auth.noMDDisclaimer") }}
        </div>
        <v-divider></v-divider>

        <div>
          {{ $t("auth.cookiePolicy") }}
          <a
            href="https://dicom.vision/web-privacy-policy-cookie"
            target="_blank"
            >Cookie Policy</a
          >
        </div>
        <div class="text-caption">
          <strong>{{ new Date().getFullYear() }} — DICOM-VISION</strong>
        </div>
      </v-footer>
    </v-main>
  </v-app>
</template>

<script>
import {
  demoUserCredentials,
  dicomImportTableHeaders,
  schemaToStackMap
} from "@/js/preferences";
import { stacksToSchemaFormat } from "@/js/utils.dicom";

const metadata = [
  ...Object.values(schemaToStackMap.patient),
  ...Object.values(schemaToStackMap.study),
  ...Object.values(schemaToStackMap.series)
];
const defaultUploadStatus = Object.freeze({
  completed: false,
  errors: {},
  loading: false,
  progress: {}
});
export default {
  name: "AuthWrapper",
  data() {
    return {
      importOptions: {
        allowAnonymization: true,
        headers: dicomImportTableHeaders,
        inputModes: { filesystem: true, pacs: true },
        metadata: [...new Set(metadata)], // uniq metadata
        steps: [], // configured in mounted function,
        disclaimer: "  "
      },
      uploadStatus: { ...defaultUploadStatus },
      loginFormHidden: false,
      hasUploaded: false
    };
  },
  /*
  computed: {
    logoClass() {
      const baseClass = "splash-screen d-flex flex-column white--text"
      if (this.seriesLoaded) {
        baseClass =+ "justify-center"
      } else if ()
      "pa-12";
      return baseClass;
    },
  },
  */
  mounted() {
    // reset stores on user logout
    this.$store.commit("dashboard/reset");
    this.$store.commit("viewer/reset");
  },
  methods: {
    demoUserLogin() {
      this.$store
        .dispatch("auth/login", demoUserCredentials)
        .then(({ user }) => {
          if (user.is_demo) {
            this.$router.replace("/");
          } else {
            console.warn("Not a demo account:", user);
          }
        })
        .catch(error => console.error(error));
    },
    openViewer(data) {
      this.$store.commit("viewer/setServer", false);
      const schemaData = stacksToSchemaFormat(data);
      schemaData.forEach(study => {
        study.series.forEach(s => {
          // data is uploading/has been uploaded (hybrid mode): set as already downloaded
          s.download_completed = true;
          // track also uploading status to activate/deactivate 3D tools
          // (needed if upload ends before opening series in viewer)
          const p = this.uploadStatus.progress[s.id];
          s.upload_completed = p && p[0] === p[1];
        });
      });
      this.$store.commit("viewer/initData", schemaData);
      this.$router.push({ name: "viewer" });
    },
    upload() {
      this.hasUploaded = true;
    },
    onNewSeriesLoaded() {
      this.loginFormHidden = true;
    },
    freeUserLogin() {
      this.$router.replace({ name: "viewer" });
    }
  }
};
</script>

<style lang="scss" scoped>
.v-application--wrap {
  min-height: 100vh;
  height: auto;
  overflow-y: auto;
}

.v-main {
  min-height: 100vh;
  overflow-y: auto;
}

.main-box {
  min-height: calc(100vh - 80px);
  overflow-y: auto;
  overflow-x: hidden;
}

.auth-box {
  flex-grow: 1;
  min-height: 90vh;
  max-height: none;
  overflow-y: auto;
  width: 100%;
  overflow-x: hidden;

  img {
    max-height: 100%;
    max-width: 200%;

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      max-height: 80%;
    }
  }
}

.image-container {
  display: flex;
  justify-content: center;
}

.login-form-wrapper {
  font-family: $highlights-font-family;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  flex-basis: 50%;
  min-width: 300px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    flex-basis: 50%;
  }

  ::v-deep form {
    max-width: 500px;
    margin: auto;
  }
}

/* Ensure scrolling is enabled on mobile screens */
@media (max-width: 600px) {
  .v-application--wrap {
    min-height: 100vh;
    overflow-y: auto;
  }

  .v-main {
    height: auto;
    overflow-y: auto;
  }

  .auth-box {
    min-height: 100vh;
    max-height: none;
    overflow-y: auto;
  }
}
</style>
