<template>
  <div class="mx-auto">
    <ditto-form
      :dark="true"
      :fields="fields"
      :fields-style="{ 'flex-basis': '100%' }"
      :loading="loading"
      submit-label="login"
      v-model="form"
      @submit="submit"
    >
      <template v-slot:header>
        <div v-if="title !== ''">
          <h2 class="text-uppercase primary--text my-4">
            {{ $t(title) }}
          </h2>
        </div>

        <div>
          <template v-if="localAlertMessage || error">
            <v-alert
              v-if="localAlertMessage"
              close-icon="mdi-close"
              dense
              dismissible
              outlined
              :style="{ lineHeight: 1.25 }"
              :type="alertType"
              @input="dismissAlert"
            >
              {{ $t(localAlertMessage) }}
            </v-alert>

            <v-alert v-if="error" dense outlined type="error">
              <span v-html="error" /><br /><br /><a
                v-if="error"
                href="#"
                @click="resendActivation"
                >Resend Activation Email</a
              >
            </v-alert>
          </template>
        </div>
      </template>

      <template v-slot:footer>
        <div
          class="d-flex justify-space-between mt-6"
          :style="{ 'flex-basis': '100%' }"
        >
          <div v-if="allowPasswordReset">
            <a @click="demoRequest"><b>DEMO</b></a>
          </div>
          <div v-if="allowPasswordReset">
            <a @click="resetPassword"><b>Forgot Password?</b></a>
          </div>
          <div v-if="allowUserRegistration">
            New here?
            <a @click="register">Sign up</a>.
          </div>
        </div>
      </template>
    </ditto-form>
  </div>
</template>

<script>
import Form from "@/plugins/form/Form.vue";
import { customizeRules } from "../../form/rules";

// Skip email/password default validation
customizeRules({
  emailValidationRegex: new RegExp(".*"),
  passwordValidationRegex: new RegExp(".*")
});

export default {
  name: "LoginForm",
  components: { DittoForm: Form },
  props: {
    alertMessage: {
      // Keep original prop to avoid conflicts
      required: false,
      type: String
    },
    alertType: { default: "success", type: String },
    allowPasswordReset: { default: true, type: Boolean },
    allowUserRegistration: { default: true, type: Boolean },
    baseRoute: { default: "", type: String },
    title: { default: "login", type: String },
    dark: { type: Boolean, default: false }
  },
  data: () => ({
    loading: false,
    error: null,
    localAlertMessage: "", // Stores activation confirmation or failure message
    fields: [
      { label: "email", key: "email", required: () => true, type: "email" },
      {
        component: "PasswordField",
        label: "password",
        key: "password",
        required: () => true,
        type: "password"
      }
    ],
    form: { email: "", password: "" }
  }),

  mounted() {
    this.$store.commit("auth/reset");
    if (this.$route.query.email) {
      this.form.email = this.$route.query.email;
    }
    console.log(this.form.email);
    // Use Vue Router query parameters for activation messages
    if (this.$route.query.status === "success") {
      this.localAlertMessage = "Your account has been successfully activated!";
    } else if (this.$route.query.status === "error") {
      this.error =
        this.$route.query.message || "Activation failed. Please try again.";
    }

    // If `alertMessage` prop exists, use it (for backward compatibility)
    if (this.alertMessage) {
      this.localAlertMessage = this.alertMessage;
    }
  },

  methods: {
    dismissAlert() {
      this.localAlertMessage = "";
      this.error = "";
      this.$router.replace({ query: {} });
    },
    submit() {
      this.error = null;
      this.loading = true;

      this.$store
        .dispatch("auth/login", this.form)
        .then(() => this.$router.replace(`${this.baseRoute}/`))
        .catch(error => {
          let details = "";
          if (error.body) {
            if (error.body.email || error.body.password) {
              if (error.body.email) {
                details += `${this.$t("email")}:<br />`;
                details += error.body.email.join("<br />");
              }
              if (error.body.password) {
                error.body.email ? (details += "<br /><br />") : null;
                details += `${this.$t("password")}:<br />`;
                details += error.body.password.join("<br />");
              }
            } else {
              details =
                error.body.detail ||
                `ERROR ${error.status} - ${error.statusText} ${error.body}`;
            }
          } else {
            details = error;
          }
          this.error = details;
        })
        .finally(() => (this.loading = false));
    },
    resendActivation() {
      this.$store
        .dispatch("auth/resendActivation", { email: this.form.email })
        .then(() => {
          this.localAlertMessage =
            "A new activation email has been sent to your email address.";
          this.error = null;
        })
        .catch(() => {
          this.error =
            "Failed to send activation email. Please try again later.";
        })
        .finally();
    },
    register() {
      this.$router.replace({ name: "register" });
    },
    demoRequest() {
      this.$emit("request-demo");
    },
    resetPassword() {
      this.$router.replace({
        name: "forgot-password",
        query: { email: this.form.email }
      });
    }
  }
};
</script>

<style scoped src="./style.css"></style>
